import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import SearchBar from '../../../search/SearchBar';
import Toaster from '../../../toaster/Toaster';
import AccountSwitcher from '../../../accountswitcher/AccountSwitcher';
import ThemeSelector from '../../../../theme/ThemeSelector';
import { selectedThemeAtom } from '../../../../theme/ThemeAtom';
import { AnemiaTheme, LogoChoice } from '../../../../types';
import AppSettings from '../../../../helpers/settings/AppSettings';
import DxLink from '../../../dxlink/DxLink';

import GenesysLogoPride from '../../../../images/genesys-logo-pride.svg';
import GenesysLogo from '../../../../images/genesys-logo-default.svg';

import './Header.scss';
import NotificationsWidget from './notifications/NotificationsWidget';
import CmsApi from '../../../../helpers/CmsApi';
import PublicLogout from '../../../publiclogout/PublicLogout';
import { loginsEnabled } from '../../../../helpers/settings/SettingsManager';

interface IProps {
	hideSearch?: boolean;
	children?: ReactNode;
}

function Header(props: IProps) {
	const theme = useRecoilValue(selectedThemeAtom());
	const logoChoice = useRecoilValue(AppSettings.logoChoiceOption());
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

	useEffect(() => {
		(async () => setIsAuthorized(!!(await CmsApi.checkAuthorization())))();
	}, []);

	let themeLogo;
	switch (theme) {
		case AnemiaTheme.DefaultDark: {
			themeLogo = logoChoice === LogoChoice.Pride ? GenesysLogoPride : GenesysLogo;
			break;
		}
		default: {
			themeLogo = logoChoice === LogoChoice.Pride ? GenesysLogoPride : GenesysLogo;
			break;
		}
	}

	return (
		<React.Fragment>
			<div className="layout-header">
				<Link to="/" className="header-image-link">
					<img src={themeLogo} className="header-image" alt="Genesys Cloud Developer Center" />
					<span className="header-image-text">{process.env.REACT_APP_SITE_NAME || 'Yeti UI'}</span>
				</Link>
				{props.hideSearch !== true && <SearchBar enableHotkey={true} />}
				{props.children}
				<ThemeSelector />
				<div className="header-button-group">
					{loginsEnabled && (
						<>
							{process.env.REACT_APP_REQUIRES_AUTHORIZATION === 'true' || isAuthorized ? (
								<DxLink href="/me" className="button-item" title="view profile for logged in user">
									<GenesysDevIcon icon={GenesysDevIcons.AppUserSolid} />
								</DxLink>
							) : (
								<DxLink href="/publiclogin" className="button-item" title="learn about Developer Center accounts and log in">
									<GenesysDevIcon icon={GenesysDevIcons.AppUser} />
								</DxLink>
							)}
							<NotificationsWidget />
							{process.env.REACT_APP_REQUIRES_AUTHORIZATION !== 'true' && isAuthorized && <PublicLogout />}
						</>
					)}
				</div>
				<div className="account-switcher-header-container">
					<AccountSwitcher className="header-account-switcher" />
				</div>
				<Toaster />
			</div>
		</React.Fragment>
	);
}

export default Header;
