import React from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton } from 'genesys-react-components';

import Tag from './Tag';

import './TagCloud.scss';

interface IProps {
	tags: string[];
	allowEdit?: boolean;
	removeClicked?: { (tag: string, position: number): void };
	align?: 'left' | 'center' | 'right';
	className?: string;
}

export default function TagCloud(props: IProps) {
	return (
		<div className={`tag-cloud align-${props.align || 'center'} ${props.className || ''}`}>
			{props.tags.map((tag, i) => (
				<div className={`tag-container ${props.allowEdit === true ? 'allow-edit' : ''}`} key={`${i}${tag.replaceAll(/^[a-z0-9]/gi, '-')}`}>
					<Tag>
						{tag}
						{props.allowEdit === true && (
							<DxButton
								type="link"
								className="remove-button"
								onClick={() => {
									if (props.removeClicked) props.removeClicked(tag, i);
								}}
							>
								<GenesysDevIcon icon={GenesysDevIcons.AppMinusSolid} />
							</DxButton>
						)}
					</Tag>
				</div>
			))}
		</div>
	);
}
