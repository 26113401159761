import React, { useEffect } from 'react';
import { GenesysDevIcons } from 'genesys-dev-icons';
import { useRecoilValue } from 'recoil';

import Header from './anemia/header/Header';
import SiteBanner from './anemia/sitebanner/SiteBanner';
import SearchBar from '../search/SearchBar';
import Card from '../cards/Card';
import Footer from './anemia/footer/Footer';
import CardGrid from '../cards/CardGrid';
import { selectedThemeAtom } from '../../theme/ThemeAtom';

import heroImage from './anemia/images/swishy-background-2000.png';
import yetiWave from './anemia/images/yeti-wave.png';

import './BootstrapBackfill.scss';
import './anemia/typography.scss';
import './anemia/movethisstuff.scss';
import './LandingPage.scss';

function LandingPage() {
	const theme = useRecoilValue(selectedThemeAtom());

	useEffect(() => {
		document.title = 'Genesys Cloud Developer Center'; //make sure page title is set
	}, []);
	return (
		<div className={`layout landing-layout ${theme}`}>
			<SiteBanner />
			<Header />
			<div className="layout-body">
				<div className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
					<img src={yetiWave} className="yeti-wave" alt="Your friendly neighborhood yeti waving hello" width="316" height="280" />
					<div className="hero-contents">
						<div className="hero-title">Build your experience with Genesys Cloud</div>
						<div className="hero-text">
							Find resources such as code samples, tutorials, and tools to help integrate Genesys Cloud into your contact center.
						</div>
						<SearchBar />
					</div>
				</div>
				<CardGrid columns={3}>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'API Explorer',
							icon: GenesysDevIcons.DestApi,
							link: '/devapps/api-explorer',
							teaser:
								"Looking for an interactive technical reference for the Genesys Cloud Platform API where you can try API requests without writing any code? You've found it!",
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'Developer Tools',
							icon: GenesysDevIcons.DestTool,
							link: '/devapps/',
							teaser:
								'Just like the Dark Knight, the Bright Yeti has a utility belt full of neat gadgets! Come check out the Notifications Monitor, Analytics Query Builder, Chat Tester, and more.',
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'Genesys for Builders',
							icon: GenesysDevIcons.AppUser,
							link: '/awsguides/',
							teaser:
								'Building unique customer experiences utilizing Genesys and Amazon Web Services to address business-specific use cases.',
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'Blueprints',
							icon: GenesysDevIcons.DestBlueprint,
							link: '/blueprints/',
							teaser:
								'These projects provide examples of API usage, 3rd party integrations, and other concepts demonstrated via a working project code.',
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'Guides',
							icon: GenesysDevIcons.DestGuide,
							link: '/guides/',
							teaser: 'Browse through our code-first guides that offer deep dives into the Genesys Cloud platform.',
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'Videos',
							icon: GenesysDevIcons.DestVideo,
							link: '/video/',
							teaser:
								'If a picture is worth a thousand words, a 10 minute video at 30 fps is worth 18 million! Take a look through our DevDrop series for short briefs as well as the DevCast Webinar library for longer form broadcasts.',
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'Dev Blog',
							icon: GenesysDevIcons.DestBlog,
							link: '/blog/',
							teaser:
								'Visit our weblog for dev-focused articles about APIs, integrations, feature announcements and updates, and all things Genesys Cloud.',
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'Forum',
							icon: GenesysDevIcons.DestForum,
							link: '/forum/',
							teaser:
								'Have a question? Have an answer? Come engage with other customers and real live Genesys product devs on the Genesys Cloud Developer Forum.',
						}}
					/>
					<Card
						cardStyle="icon-large"
						data={{
							title: 'AppFoundry',
							icon: GenesysDevIcons.DestAppFoundry,
							link: '/appfoundry/',
							teaser:
								'AppFoundry is a marketplace of solutions that offers Genesys customers a curated selection of integrations and applications to help create great customer experiences.',
						}}
					/>
				</CardGrid>
				<div className="popular-topics-title">
					<h1>Explore Our Popular Topics</h1>
				</div>
				<CardGrid columns={3}>
					<Card
						cardStyle="icon"
						data={{
							title: 'Analytics & Data Management',
							icon: GenesysDevIcons.IaAnalyticsDataManagement,
							teaser:
								'The Analytics & Data Management category contains content relating to accessing and extracting historical conversation data from Genesys Cloud.',
							link: '/analyticsdatamanagement/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Authorization',
							icon: GenesysDevIcons.IaAuthorization,
							teaser:
								'The Authorization category contains content about Authorizing custom applications using one of the Genesys Cloud OAuth2 grants.',
							link: '/authorization/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Billing',
							icon: GenesysDevIcons.IaBilling,
							teaser: 'The Billing category contains content about accessing platform billing data.',
							link: '/billing/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Communication & Digital Channels',
							icon: GenesysDevIcons.IaDigital,
							teaser: 'The Communication & Digital Channels category contains content about the digital (all non-voice media).',
							link: '/commdigital/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'GDPR & Privacy',
							icon: GenesysDevIcons.IaGdprPrivacy,
							teaser: 'The GDPR & Privacy category contains content about GDPR, Org trust, and other regulatory and compliance topics.',
							link: '/gdprprivacy/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Notifications & Alerts',
							icon: GenesysDevIcons.IaNotificationsAlerts,
							teaser:
								'The Notifications & Alerts category contains content about real-time notifications, Event Bridge, and general alerting.',
							link: '/notificationsalerts/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Organization',
							icon: GenesysDevIcons.IaOrganization,
							teaser: 'The Organization category contains content about org configuration and other larger concepts.',
							link: '/organization/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Platform',
							icon: GenesysDevIcons.IaPlatform,
							teaser: 'The Platform category contains meta-content about the platform as well as some integration points.',
							link: '/platform/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Routing & Conversation Handling',
							icon: GenesysDevIcons.IaRouting,
							teaser:
								'The Routing & Conversation Handling category contains content about configuring routing and interacting with conversations.',
							link: '/routing/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'Telephony',
							icon: GenesysDevIcons.IaTelephony,
							teaser: 'The Telephony category contains content about Edges and related telephony configurations.',
							link: '/telephony/',
						}}
					/>
					<Card
						cardStyle="icon"
						data={{
							title: 'User & Agent Management',
							icon: GenesysDevIcons.IaUserAgentManagement,
							teaser: 'The User & Agent Management category contains content about interacting with Genesys Cloud user entites.',
							link: '/useragentman/',
						}}
					/>
				</CardGrid>
				<Footer />
			</div>
		</div>
	);
}

export default LandingPage;
