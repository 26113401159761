import { useEffect, useState } from 'react';
import { default as yaml } from 'js-yaml';
import { default as sjcl } from 'sjcl';
import { default as moment } from 'moment-timezone';
import DualListBox, { ValueOption } from 'react-dual-listbox';
import { v4 as uuidv4 } from 'uuid';
import { CodeFence, DxTextbox, DxToggle } from 'genesys-react-components';
import { Buffer } from 'buffer';

import DxLink from '../../dxlink/DxLink';
import { ToastType, addToast } from '../../../helpers/atoms/ToastAtom';
import AssetLoader from '../../../helpers/AssetLoader';

import 'react-dual-listbox/lib/react-dual-listbox.css';

interface Scope {
	name: string;
	internal?: boolean;
}

interface Credentials {
	clientId: string;
	clientSecret: string;
}

interface OAuthClientConfig {
	id: string;
	name: string;
	description: string;
	pbkdf2secret: {
		dk: string;
		salt: string;
		len: number;
		iter: number;
		hash: string;
	};
	scope: string[];
	granttypes: string[];
	redirects: string[];
	accessvalidityseconds: number;
	refreshvalidityseconds?: number;
	environments?: string[];
}

export default function AuthApiBuiltinClientGenerator() {
	const [accessvalidityseconds, setAcessvalidityseconds] = useState<number>(36000);
	const [refreshvalidityseconds, setRefreshvalidityseconds] = useState<number>(18000);
	const [availableScopes, setAvailableScopes] = useState<ValueOption<string>[]>([]);
	const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
	const [redirectURIs, setRedirectURIs] = useState<string[]>([]);
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [isAuthCode, setIsAuthCode] = useState<boolean>(false);
	const [isImplicit, setIsImplicit] = useState<boolean>(false);
	const [isPassword, setIsPassword] = useState<boolean>(false);
	const [isCredentials, setIsCredentials] = useState<boolean>(false);
	const [isDev, setIsDev] = useState<boolean>(false);
	const [isTest, setIsTest] = useState<boolean>(false);
	const [isProd, setIsProd] = useState<boolean>(false);
	const [credentials, setCredentials] = useState<Credentials>();
	const [config, setConfig] = useState<OAuthClientConfig>();

	useEffect(() => {
		(async () => {
			// Load scopes
			try {
				const data = await AssetLoader.get('/services/auth-api/scopes.yaml', true);
				const scopes = yaml.load(data) as Scope[];
				const newScopes: ValueOption<string>[] = [];
				scopes.forEach((s) => newScopes.push({ value: s.name, label: `${s.name}${s.internal ? ' (internal)' : ''}` }));
				setAvailableScopes(newScopes);
			} catch (err) {
				console.log(err);
				addToast({
					toastType: ToastType.Critical,
					message: 'Failed to load Auth API scopes list. See console for details.',
				});
				// return;
				setAvailableScopes([]);
			}

			// Generate new credentials
			const iterations = 30000;
			const saltLength = 16;
			const secretLength = 32;
			// Generate salt
			let saltValues = new Uint8Array(saltLength);
			window.crypto.getRandomValues(saltValues);
			const salt = Buffer.from(String.fromCharCode.apply(null, saltValues as unknown as number[]))
				.toString('base64')
				.replace(/=/g, '');
			// Generate Secret
			let secretValues = new Uint8Array(secretLength);
			window.crypto.getRandomValues(secretValues);
			const secret = Buffer.from(String.fromCharCode.apply(null, secretValues as unknown as number[]))
				.toString('base64')
				.replace(/=/g, '');
			// Generate the derived key
			// https://bitwiseshiftleft.github.io/sjcl/doc/sjcl.misc.html
			const key = sjcl.misc.pbkdf2(secret, sjcl.codec.base64.toBits(salt), iterations);

			const clientId = uuidv4();
			const newConfig = {
				id: clientId,
				name,
				description,
				pbkdf2secret: {
					dk: sjcl.codec.base64.fromBits(key).replace(/=/g, ''),
					salt: salt,
					len: secretLength,
					iter: iterations,
					hash: 'sha256',
				},
				scope: [],
				granttypes: [],
				redirects: [],
				accessvalidityseconds: accessvalidityseconds,
			};
			setConfig(newConfig);
			setCredentials({
				clientId,
				clientSecret: secret,
			});

			// Invoke standard config update logic (pass in to prevent race condition with state update)
			updateConfig(newConfig);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateConfig();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accessvalidityseconds,
		refreshvalidityseconds,
		selectedScopes,
		redirectURIs,
		name,
		description,
		isAuthCode,
		isImplicit,
		isPassword,
		isCredentials,
		isDev,
		isTest,
		isProd,
	]);

	const updateConfig = (existingConfig?: OAuthClientConfig) => {
		// Create configuration object
		const newConfig = structuredClone(existingConfig || config);
		if (!newConfig) return;

		// Determine grant types
		const granttypes = [];
		if (isAuthCode) granttypes.push('code');
		if (isImplicit) granttypes.push('token');
		if (isPassword) granttypes.push('password');
		if (isCredentials) granttypes.push('credentials');

		// Determine environments
		const environments = [];
		if (isDev) environments.push('dev');
		if (isTest) environments.push('test');
		if (isProd)
			Array.prototype.push.apply(environments, [
				'prod',
				'fedramp-use2-core',
				'prod-use2',
				'prod-usw2',
				'prod-euw1',
				'prod-apse2',
				'prod-apne1',
				'prod-apne2',
				'prod-apne3',
				'prod-euc1',
				'prod-euc2',
				'prod-cac1',
				'prod-euw2',
				'prod-aps1',
				'prod-sae1',
				'prod-mec1',
			]);

		// Apply config
		newConfig.name = name;
		newConfig.description = description;
		newConfig.scope = selectedScopes;
		newConfig.granttypes = granttypes;
		newConfig.redirects = redirectURIs || [];
		newConfig.accessvalidityseconds = accessvalidityseconds;
		if (isAuthCode) {
			newConfig.refreshvalidityseconds = refreshvalidityseconds;
		} else {
			delete newConfig.refreshvalidityseconds;
		}
		if (environments.length > 0) {
			newConfig.environments = environments;
		} else {
			delete newConfig.environments;
		}
		setConfig(newConfig);
	};

	const secondsToTime = (seconds: number) => {
		let d = moment.duration(seconds, 'seconds');
		let display = '';
		if (d.days() > 0) display += `${d.days()}d ${d.hours()}h `;
		else if (d.hours() > 0) display += `${d.hours()}h `;
		display += `${d.minutes()}m ${d.seconds()}s`;
		return display;
	};

	return (
		<div>
			<p>
				Client configurations may be built in to auth-api. Builtin clients will always be available, and must be updated or deleted via a
				pull request to auth-api. The builtin clients are compiled from the individual client files in <code>builtin_clients.d</code>.
			</p>
			<p>
				Built-in clients make it very easy to differentiate API requests between Genesys and third-parties. Customers, partners, and PS are
				all considered third-parties. Genesys Development and Testing created clients are considered Genesys. This differentiation will be
				used in business dashboards and potentially for billing by usage. Today over half of the API requests come from third-parties.
			</p>
			<p>
				Built-in clients are global across all regions and environments (dev, test, prod, apse2, apne1, euc1, euw1, usw2, cac1, apne2, and
				euw2). Using the same client across all regions makes it easier for you to manage your OAuth clients. Also, since they are hardcoded
				there is no danger of existing clients being accidentally edited or deleted. As you probably know editing a client could cause an
				outage for your application. This is actually the reason we originally created built-in clients (yes, there is an RCA for that).
			</p>
			<p>
				<strong>All Genesys Development and Testing applications must use a built-in OAuth Client</strong>
			</p>
			<p>
				<strong>
					Make sure you your change to auth-api has deployed to all environments before you merge the change to use it in your app.
				</strong>
			</p>
			<h2>Client Configuration</h2>
			<DxTextbox label="Name" description="Friendly name that will appear in sumo and newrelic" onChange={setName} />
			<DxTextbox label="Description" description="More details about how this client is used" onChange={setDescription} />
			<p>
				<strong>Grant Types</strong>
			</p>
			<DxToggle
				label="Auth Code grant"
				initialValue={isAuthCode}
				onChange={(value) => setIsAuthCode(value === true)}
				description="preferred, clients that can secure a secret"
			/>
			<DxToggle
				label="Implicit grant"
				initialValue={isImplicit}
				onChange={(value) => setIsImplicit(value === true)}
				description="clients that are not able to secure the password; directory/collaborate currently uses this."
			/>
			<DxToggle
				label="Password grant"
				initialValue={isPassword}
				onChange={(value) => setIsPassword(value === true)}
				description="INTERNAL use only. Allows for creating tokens without using the login web page. We do not expose this to customers. Used by service-to-service or test automation apps."
			/>
			<DxToggle
				label="Credentials"
				initialValue={isCredentials}
				onChange={(value) => setIsCredentials(value === true)}
				description="INTERNAL use only. Used only to integrate with the credential service. Use of a token created with this client to make API requests will fail."
			/>
			<DxTextbox
				label="Redirect URIs"
				inputType="textarea"
				description="List of redirect urls when using code or token grant types, one per line."
				onChange={(redirects) => {
					setRedirectURIs(
						(redirects || '')
							.split('\n')
							.map((v) => v.trim())
							.filter((v) => v !== '')
					);
				}}
			/>
			<p>
				<strong>Redirect URI variables</strong>
			</p>
			<p>The following template variables are available</p>
			<ul>
				<li>
					<code>{`{{domainName}}`}</code>: Add the URI for all environments and regions. (example:
					<code>https://my-app.{`{{domainName}}`}/oauth/callback</code>)
				</li>
				<li>
					<code>{`{{domainNameLower}}`}</code>: Add the URI for dev and test. (example:
					<code>https://my-app.{`{{domainNameLower}}`}/oauth/callback</code>)
				</li>
				<li>
					<code>{`{{domainNameProduction}}`}</code>: Add the URI for all production regions. (example:
					<code>https://my-app.{`{{domainNameProduction}}`}/oauth/callback</code>)
				</li>
				<li>
					<code>{`{{envName}}`}</code>: Add the URI for a specific region. (example:
					<code>https://my-app/oauth/callback/{`{{envName}}`}</code>)
				</li>
				<li>
					<code>{`{{envNameLowers}}`}</code>: Add the URI for either the dca or tca region only. (example:
					<code>https://my-app/oauth/callback/{`{{envNameLowers}}`}</code>)
				</li>
				<li>
					<code>{`{{envNameProduction}}`}</code>: Add the URI for a specific prod region only. (example:
					<code>https://my-app/oauth/callback/{`{{envNameProduction}}`}</code>)
				</li>
				<li>
					<code>{`{{domainName}}`}</code> + <code>{`{{envName}}`}</code>: Add the URI for a specific domain and region. (example:
					<code>
						https://my-app.{`{{domainName}}`}/oauth/callback/{`{{envName}}`}
					</code>
					)
				</li>
			</ul>
			<DxTextbox
				inputType="integer"
				label="Token Duration (seconds)"
				initialValue={`${accessvalidityseconds}`}
				onChange={(value) => setAcessvalidityseconds(parseInt(value) || 3600)}
				description={`Duration in seconds before tokens will expire. Shorter is generally better; 36000 (10 hours) is recommended. Formatted value: ${secondsToTime(
					accessvalidityseconds
				)}`}
			/>
			{isAuthCode && (
				<DxTextbox
					inputType="integer"
					label="Refresh Duration (seconds)"
					initialValue={`${refreshvalidityseconds}`}
					onChange={(value) => setRefreshvalidityseconds(parseInt(value) || 18000)}
					description={`Duration in seconds before refresh tokens will expire. Valid only for code grant types. Should be less than token duration; 18000 (5 hours) is recommended. Formatted value: ${secondsToTime(
						refreshvalidityseconds
					)}`}
				/>
			)}
			<p>
				<strong>Scopes</strong>
			</p>
			<DualListBox
				options={availableScopes}
				selected={selectedScopes}
				onChange={setSelectedScopes}
				icons={{
					moveLeft: '<',
					moveAllLeft: '<<',
					moveRight: '>',
					moveAllRight: '>>',
				}}
			/>
			<p>
				<strong>Environments</strong>
			</p>
			<p>List of environments in which this client is available. If not specified, the client will be available in all environments.</p>
			<DxToggle label="Dev" initialValue={isDev} onChange={(value) => setIsDev(value === true)} />
			<DxToggle label="Test" initialValue={isTest} onChange={(value) => setIsTest(value === true)} />
			<DxToggle label="Prods" initialValue={isProd} onChange={(value) => setIsProd(value === true)} />
			<h2>Output</h2>
			<h3>Credentials</h3>
			<p>
				<strong>This is the only time the secret will be visible.</strong> Be sure to save it before leaving this page if you are using a
				code or client credential grant.
			</p>
			<CodeFence value={yaml.dump(credentials)} noCollapse={true} />
			<h3>Configuration Data</h3>
			<p>
				Add this output to a new file in{' '}
				<DxLink href="https://bitbucket.org/inindca/auth-api/src/master/builtin_clients.d/">auth-api/builtin_clients.d/</DxLink>
			</p>
			<CodeFence value={yaml.dump([config])} noCollapse={true} />
		</div>
	);
}
