import { useEffect, useState } from 'react';
import { AlertBlock, DxButton, DxTextbox } from 'genesys-react-components';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import { setFeedback, useFeedback } from './FeedbackAtom';
import { ToastType, addToast } from '../../helpers/atoms/ToastAtom';
import CmsApi from '../../helpers/CmsApi';
import { FeedbackSentiment } from '../../helpers/CmsApiTypes';

import './FeedbackWidget.scss';

const MIN_MESSAGE_LENGTH = 30;
const MAX_MESSAGE_LENGTH = 3000;
const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default function FeedbackWidget() {
	const feedbackData = useFeedback();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [validationError, setValidationError] = useState<string>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [sentiment, setSentiment] = useState<FeedbackSentiment>(FeedbackSentiment.None);
	const [isNameValid, setIsNameValid] = useState<boolean>();
	const [isEmailValid, setIsEmailValid] = useState<boolean>();
	const [isEmployee, setIsEmployee] = useState<boolean>(false);

	useEffect(() => {
		const tempIsNameValid = !feedbackData.name ? undefined : feedbackData.name.trim().length >= 3;
		const tempIsEmailValid = !feedbackData.email ? undefined : feedbackData.email.match(EMAIL_REGEX) ? true : false;
		const userEmail = CmsApi.currentUser?.email || feedbackData.email;

		// Update valid states
		setIsNameValid(tempIsNameValid);
		setIsEmailValid(tempIsEmailValid);
		setIsEmployee(userEmail !== undefined && userEmail.toLowerCase().endsWith('@genesys.com'));

		// Set error message
		if (tempIsNameValid === false) {
			setValidationError('Your name must be at least three characters long');
		} else if (tempIsEmailValid === false) {
			setValidationError('Your email address is invalid');
		} else {
			setValidationError(undefined);
		}
	}, [feedbackData]);

	if (!isOpen) {
		return (
			<div className="feedback-widget">
				<div className="sentiment-container collapsed">
					<span>Was this page helpful?</span>
					<DxButton className={`sentiment-button${sentiment === FeedbackSentiment.Positive ? ' active' : ''}`} type="link">
						<GenesysDevIcon
							className="thumbs-icon"
							icon={GenesysDevIcons.AppThumbsUp}
							onClick={() => {
								setSentiment(FeedbackSentiment.Positive);
								setIsOpen(true);
							}}
						/>
					</DxButton>
					<DxButton className={`sentiment-button${sentiment === FeedbackSentiment.Negative ? ' active' : ''}`} type="link">
						<GenesysDevIcon
							className="thumbs-icon"
							icon={GenesysDevIcons.AppThumbsDown}
							onClick={() => {
								setSentiment(FeedbackSentiment.Negative);
								setIsOpen(true);
							}}
						/>
					</DxButton>
					<DxButton className={`sentiment-button${sentiment === FeedbackSentiment.Bug ? ' active' : ''}`} type="link">
						<GenesysDevIcon
							className="thumbs-icon"
							icon={GenesysDevIcons.AppBug}
							onClick={() => {
								setSentiment(FeedbackSentiment.Bug);
								setIsOpen(true);
							}}
						/>
					</DxButton>
				</div>
			</div>
		);
	}

	return (
		<div className="feedback-widget">
			<h2 className="feedback-heading">{process.env['REACT_APP_SITE_NAME']} Feedback</h2>
			<p>
				Need help with something? Found a bug? Have something to say? Fill out the form below and we'll get back to you.{' '}
				{process.env['REACT_APP_FEEDBACK_PROMPT']}
			</p>
			<div className="sentiment-container">
				<span>Sentiment:</span>
				<DxButton
					className={`sentiment-button${sentiment === FeedbackSentiment.Positive ? ' active' : ''}`}
					type={sentiment === FeedbackSentiment.Positive ? 'primary' : 'link'}
				>
					<GenesysDevIcon
						className="thumbs-icon"
						icon={GenesysDevIcons.AppThumbsUp}
						onClick={() => setSentiment(FeedbackSentiment.Positive)}
					/>
				</DxButton>
				<DxButton
					className={`sentiment-button${sentiment === FeedbackSentiment.Negative ? ' active' : ''}`}
					type={sentiment === FeedbackSentiment.Negative ? 'primary' : 'link'}
				>
					<GenesysDevIcon
						className="thumbs-icon"
						icon={GenesysDevIcons.AppThumbsDown}
						onClick={() => setSentiment(FeedbackSentiment.Negative)}
					/>
				</DxButton>
				<DxButton
					className={`sentiment-button${sentiment === FeedbackSentiment.Bug ? ' active' : ''}`}
					type={sentiment === FeedbackSentiment.Bug ? 'primary' : 'link'}
				>
					<GenesysDevIcon className="thumbs-icon" icon={GenesysDevIcons.AppBug} onClick={() => setSentiment(FeedbackSentiment.Bug)} />
				</DxButton>
			</div>
			{!CmsApi.currentUser && (
				<div className="input-row">
					<DxTextbox
						className={isNameValid === false ? 'invalid' : ''}
						label="Name"
						placeholder="Name (Optional)"
						onChange={(value) => {
							setFeedback(value, 'name');
						}}
					/>
					<DxTextbox
						className={isEmailValid === false ? 'invalid' : ''}
						inputType="email"
						label="Email Address"
						placeholder="Email Address (Optional)"
						onChange={(value) => {
							setFeedback(value, 'email');
						}}
					/>
				</div>
			)}
			<DxTextbox
				className="message-text"
				inputType="textarea"
				placeholder="What's on your mind?"
				initialValue={feedbackData.message}
				disabled={isSubmitting}
				description={`Message length: ${feedbackData.message.length}/${MAX_MESSAGE_LENGTH}${
					feedbackData.message.length < MIN_MESSAGE_LENGTH ? ` (must be at least ${MIN_MESSAGE_LENGTH})` : ''
				}`}
				changeDebounceMs={-1}
				clearOnEscape={false}
				onChange={(val) => {
					const message = val?.trim() || '';
					setFeedback(message);
					if (message.length > MAX_MESSAGE_LENGTH) {
						setValidationError(`Message is too long. Maximum length is ${MAX_MESSAGE_LENGTH} characters.`);
						return;
					}
					setValidationError(undefined);
				}}
			/>
			{validationError && (
				<AlertBlock alertType="critical" title="Validation Error">
					{validationError}
				</AlertBlock>
			)}
			{isEmployee && (
				<AlertBlock alertType="info" title="Hey there Genesys employee!">
					If you have found a defect within the documentation, please follow your department's standard Genesys Cloud defect reporting
					process. To report issues with the site itself or if you are unsure how to get assistance via internal channels, please submit
					your feedback via this form or join us in the GC Developer Engagement chat room.
				</AlertBlock>
			)}
			<div className="submit-buttons">
				<DxButton
					disabled={!!validationError || feedbackData.message.length < MIN_MESSAGE_LENGTH || isSubmitting}
					onClick={async () => {
						setIsSubmitting(true);
						const ok = await CmsApi.postFeedback(feedbackData, sentiment);
						if (ok) {
							setFeedback('');
							setIsOpen(false);
							setSentiment(FeedbackSentiment.None);
							addToast({
								title: 'Feedback submitted',
								message: "Thanks for your message. We'll get back to you soon. " + process.env['REACT_APP_FEEDBACK_SUCCESS_MESSAGE'],
								toastType: ToastType.Success,
							});
						}
						setIsSubmitting(false);
					}}
				>
					{isSubmitting ? 'Sending...' : 'Submit'}
				</DxButton>
				<DxButton
					type="secondary"
					disabled={isSubmitting}
					onClick={() => {
						setFeedback('');
						setSentiment(FeedbackSentiment.None);
						setIsOpen(false);
					}}
				>
					Cancel
				</DxButton>
			</div>
		</div>
	);
}
