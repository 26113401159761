import React from 'react';
import { useSitemap } from '../../../../helpers/atoms/SitemapAtom';

import { PageContentType, PageInfo, ToolboxApp } from '../../../../types';
import PageContent from '../../../core/PageContent';

interface IProps {
	for?: ToolboxApp;
}

export default function ToolboxHelp(props: IProps) {
	const sitemap = useSitemap();

	const createPageInfo = (webpath: string) => {
		let pageInfo = sitemap.getPage(webpath, false) as PageInfo;
		if (!pageInfo) return;

		pageInfo = structuredClone(pageInfo);
		pageInfo.contentPath = pageInfo.link + '.md';
		pageInfo.contentType = PageContentType.Markdown;

		return pageInfo;
	};

	switch (props.for) {
		case ToolboxApp.ApiExplorer: {
			const pageInfo = createPageInfo('/devapps/about/api-explorer');
			if (pageInfo) return <PageContent staticPage={pageInfo} />;
			else break;
		}
		case ToolboxApp.Notifications: {
			const pageInfo = createPageInfo('/devapps/about/notifications');
			if (pageInfo) return <PageContent staticPage={pageInfo} />;
			else break;
		}
		case ToolboxApp.Pages: {
			const pageInfo = createPageInfo('/devapps/about/pages');
			if (pageInfo) return <PageContent staticPage={pageInfo} />;
			else break;
		}
	}

	// This should never actually happen in practice. It's a low-effort placeholder just in case something goes wrong.
	return <div>Welcome to the toolbox! Pick an app on the right to get started.</div>;
}
