import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import { DxItemGroup, DxItemGroupItem, DxAccordion, LoadingPlaceholder } from 'genesys-react-components';
import { GenesysDevIcons, GenesysDevIcon } from 'genesys-dev-icons';

import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import DataTable from '../../markdown/datatable/DataTable';
import { OpenAPIDefinition } from '../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../helpers/openapi/SwaggerCache';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import axios, { CancelTokenSource } from 'axios';

import './ApplicationInspector.scss';
import DxLink from '../../dxlink/DxLink';

interface Client {
	accessTokenValiditySeconds: number;
	authorizedGrantType: string;
	createdBy: any;
	dateCreated: string;
	dateModified: string;
	id: string;
	name: string;
	registeredRedirectUri: string[];
	secret: string;
	selfUri: string;
	state: string;
	scope?: string[];
	roleIds?: string[];
	modifiedBy?: any[];
}

interface Resources {
	templateUri: string;
	method: string;
	scope: string;
	permissions: string;
}

function ApplicationInspector() {
	const [dropdownItems, setDropdownItems] = useState<DxItemGroupItem[]>([]);
	const [selectedClient, setSelectedClient] = useState<DxItemGroupItem>();
	const [swagger, setSwagger] = useState<OpenAPIDefinition>();
	const [resources, setResources] = useState<Resources[] | undefined>();
	const [clients, setClients] = useState<Client[]>();
	const [orgCount, setOrgCount] = useState(0);
	const [scopesThatCanBeDowngraded, setScopesThatCanBeDowngraded] = useState<string[]>([]);
	const [extraDefinedScopes, setExtraDefinedScopes] = useState<string[]>([]);
	const [validQueryResult, setValidQueryResult] = useState(true);
	const [requiredPermissions, setRequiredPermissions] = useState<string[]>([]);
	const [requiredScopes, setRequiredScopes] = useState<string[]>([]);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const cancelToken = useRef<CancelTokenSource | undefined>();

	const LAST_NUMBER_OF_DAYS = 7;

	useEffect(() => {
		if (!selectedAccount) return;

		cancelToken.current?.cancel('Selected account was changed');
		setSelectedClient(undefined);
		setDropdownItems([]);

		selectedAccount.api
			.request({ method: 'get', url: `/api/v2/oauth/clients` })
			.then((res) => {
				setClients(res.data.entities);
				const items: DxItemGroupItem[] = res.data.entities.map((i: Client) => {
					return { label: i.name, value: i.id };
				});

				items.sort((a, b) => a.label.localeCompare(b.label));
				items.unshift({ label: '', value: '' });
				setDropdownItems(items);
			})
			.catch((err) => {
				addToast({ title: 'Failed to load clients data', message: err?.message || err, toastType: ToastType.Critical });
			});
		getSwagger().then((swag) => {
			setSwagger(swag);
		});
	}, [selectedAccount]);

	useEffect(() => {
		setResources(undefined);
		setValidQueryResult(true);

		if (selectedClient && selectedClient.label !== '') {
			const tokenSource = axios.CancelToken.source();
			cancelToken.current = tokenSource;
			let client = clients?.find((client) => client.id === selectedClient.value);

			queryAndGetResults(selectedClient.value, ['organizationId'])
				.then((resp: any) => {
					let org = 0;
					let cache: string[] = [];
					resp.results.forEach((client: any) => {
						if (client.organizationId && cache.indexOf(client.organizationId) < 0) {
							org++;
							cache.push(client.organizationId);
						}
					});
					setOrgCount(org);
				})
				.catch((err) => addToast({ title: 'Failed to get query results', message: err?.message || err, toastType: ToastType.Critical }));

			queryAndGetResults(selectedClient.value, ['templateuri', 'httpmethod'])
				.then((resp: any) => {
					if (resp) {
						let requiredPermissions: string[] = [];
						let requiredScopes: string[] = [];
						let resources: Resources[] | undefined = [];

						resp.results.forEach((method: any) => {
							if (method.status200 > 0) {
								let uri = method.templateUri;
								if (uri[0] !== '/') {
									uri = '/' + uri;
								}

								let scope = getScopeForResource(uri, method.httpMethod);

								if (scope && requiredScopes.indexOf(scope) === -1) {
									if (scope.indexOf('readonly') > -1) {
										//found readonly scope, check if there is a readwrite scope already defined
										let rwScope = scope.split(':')[0];
										if (requiredScopes.indexOf(rwScope) === -1) {
											requiredScopes.push(scope);
										}
									} else {
										//found readwrite scope, remove any readonly scopes
										requiredScopes.push(scope);
										requiredScopes = requiredScopes.filter((s: string) => s !== scope + ':readonly');
									}
								}

								let permissions = getPermissionsForResource(uri, method.httpMethod);
								if (permissions && requiredPermissions.indexOf(permissions) === -1) {
									requiredPermissions.push(permissions);
								}

								resources?.push({
									templateUri: uri,
									method: method.httpMethod,
									scope: scope,
									permissions: permissions,
								});
							}
						});

						setRequiredScopes(requiredScopes);
						setRequiredPermissions(requiredPermissions);
						if (!resources || resources.length === 0) {
							setValidQueryResult(false);
						}
						setResources(resources);

						let definedScopes: string[] = [];
						let scopesThatCanBeDowngraded: string[] = [];
						definedScopes = client?.scope || [];

						requiredScopes.forEach((reqScope: string) => {
							definedScopes = definedScopes?.filter((scope) => scope !== reqScope);

							if (reqScope.indexOf('readonly') > -1) {
								let rwScope = reqScope.split(':')[0];
								if (definedScopes.indexOf(rwScope) > -1) {
									scopesThatCanBeDowngraded.push(rwScope);
									definedScopes = definedScopes.filter((s) => s !== rwScope);
								}
							}
						});
						setScopesThatCanBeDowngraded(scopesThatCanBeDowngraded.sort());
						setExtraDefinedScopes(definedScopes.sort());
					}
				})
				.catch((err) => {
					addToast({ title: 'Failed to get query results', message: err?.message || err, toastType: ToastType.Critical });
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient]);

	function getPermissionsForResource(templateUri: string, httpMethod: string) {
		let uriDef = swagger?.paths[templateUri];
		if (uriDef) {
			let def = uriDef[httpMethod.toLowerCase()];
			if (def) {
				if (def['x-inin-requires-permissions'] && def['x-inin-requires-permissions'].permissions.length > 0) {
					let permissionType = def['x-inin-requires-permissions'].type;
					return `${permissionType} ${def['x-inin-requires-permissions'].permissions.join(',')}`;
				}
			}
		}

		return '';
	}

	function getScopeForResource(templateUri: string, httpMethod: string) {
		let uriDef = swagger?.paths[templateUri];
		let scope: string = '';

		if (uriDef) {
			let def = uriDef[httpMethod.toLowerCase()];
			if (def) {
				if (def.security && def.security.length > 0) {
					def.security.forEach((security) => {
						if (security['PureCloud OAuth']) {
							let readonlyScopes = security['PureCloud OAuth'].filter((scope) => scope.indexOf(':readonly') > 0);
							let writeScopes = security['PureCloud OAuth'].filter((scope) => scope.indexOf(':readonly') === -1);

							if (readonlyScopes.length > 0) {
								scope = readonlyScopes[0];
							} else if (writeScopes.length > 0) {
								scope = writeScopes[0];
							}
						}
					});
				}
			}
		}

		return scope;
	}

	async function getSwagger() {
		return await SwaggerCache.get();
	}

	function queryAndGetResults(clientId: string, groupByList: string[]) {
		let startDate = moment()
			.startOf('day')
			.subtract(1 * LAST_NUMBER_OF_DAYS, 'days');
		let endDate = moment().startOf('day');

		let query = {
			interval: startDate.toISOString() + '/' + endDate.toISOString(),
			groupBy: groupByList,
		};

		return new Promise((resolve, reject) => {
			if (!selectedAccount) return;
			selectedAccount.api
				.request({
					url: `/api/v2/oauth/clients/${encodeURIComponent(clientId)}/usage/query`,
					method: 'post',
					data: query,
					cancelToken: cancelToken.current?.token,
				})
				.then((usageExecution: any) => {
					let executionId = usageExecution.data.executionId;

					//Retry until you get a good response
					let interval = setInterval(() => {
						queryUsageResult(clientId, executionId)
							.then((resp: any) => {
								if (resp.data.queryStatus.toLowerCase() === 'running') {
									return;
								}

								clearInterval(interval);

								resolve(resp.data);
							})
							.catch((err) => {
								clearInterval(interval);
								reject(err);
							});
					}, 2000);
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						//console.log(err);
					} else {
						addToast({ title: 'Failed to get query results', message: err.message, toastType: ToastType.Critical });
					}
				});
		});
	}

	function queryUsageResult(clientId: string, executionId: string) {
		return new Promise((resolve, reject) => {
			if (selectedAccount) {
				selectedAccount.api
					.request({
						url: `/api/v2/oauth/clients/${encodeURIComponent(clientId)}/usage/query/results/${encodeURIComponent(executionId)}`,
						method: 'get',
						cancelToken: cancelToken.current?.token,
					})
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						if (axios.isCancel(err)) {
							//console.log(err);
						} else {
							addToast({ title: 'Error occured while getting query results', message: err.message, toastType: ToastType.Critical });
						}
						reject(err);
					});
			} else {
				reject('No Selected Account');
			}
		});
	}

	if (!selectedAccount) {
		return (
			<div className="warning-container">
				<GenesysDevIcon className="icon" icon={GenesysDevIcons.AppInfoSolid} />
				<span> Please add an account to utilize this tool</span>
			</div>
		);
	}

	let payload;

	let warning;

	let noClientSelected = false;

	if (resources) {
		if (validQueryResult) {
			payload = (
				<React.Fragment>
					<DxAccordion className="application-inspector-accordion" title="Usage">
						<div className="usage-container">
							<span>
								<span className="bold-text">Org Count:</span> {orgCount}
							</span>
							<span>
								<GenesysDevIcon icon={GenesysDevIcons.AppInfoSolid} />{' '}
								<em>
									User count is no longer available. Read more in the{' '}
									<DxLink
										href="https://developer.genesys.cloud/forum/t/emergency-breaking-change-to-usage-query/20474"
										forceNewTab={true}
										title="Usage query change announcement"
									>
										announcement
									</DxLink>
									.
								</em>
							</span>
						</div>
					</DxAccordion>

					<DxAccordion className="application-inspector-accordion" title="Permissions">
						<div>
							<p className="description">These are the permissions required to use your app:</p>
							<ul>
								{requiredPermissions.length > 0 ? requiredPermissions.map((permission, i) => <li key={i}>{permission}</li>) : <em>None</em>}
							</ul>
						</div>
					</DxAccordion>

					<DxAccordion className="application-inspector-accordion" title="Scopes">
						<div className="scope-container">
							<div>
								<h3>Required Scope</h3>
								<p className="description">These are the minimum required scopes your app needs:</p>
								<ul>{requiredScopes.length > 0 ? requiredScopes.map((scope, i) => <li key={i}>{scope}</li>) : <em>None</em>}</ul>
							</div>
							<div>
								<h3>Unused Scope</h3>
								<p className="description">These scopes are listed on your app but possibly not being used:</p>
								<ul>{extraDefinedScopes.length > 0 ? extraDefinedScopes.map((scope, i) => <li key={i}>{scope}</li>) : <em>None</em>}</ul>
							</div>
							<div>
								<h3>Downgraded Scopes</h3>
								<p className="description">These scopes are requesting read and write access, but only read may be needed:</p>
								<ul>
									{scopesThatCanBeDowngraded.length > 0 ? (
										scopesThatCanBeDowngraded.map((scope, i) => <li key={i}>{scope}</li>)
									) : (
										<em>None</em>
									)}
								</ul>
							</div>
						</div>
					</DxAccordion>

					<DxAccordion showOpen={true} className="application-inspector-accordion" title="Resources">
						<DataTable
							headerRow={{ cells: [{ content: 'Method' }, { content: 'Uri' }, { content: 'Scopes' }, { content: 'Permissions' }] }}
							rows={resources.map((r) => {
								return {
									cells: [
										{ content: r.method },
										{ content: r.templateUri },
										{ content: r.scope, align: 'left' },
										{ content: r.permissions || '', align: 'left' },
									],
								};
							})}
						/>
					</DxAccordion>
				</React.Fragment>
			);
		} else {
			warning = (
				<div className="warning-container error-warning">
					<GenesysDevIcon className="icon" icon={GenesysDevIcons.AppInfoSolid} />
					<span> No API request in the past {LAST_NUMBER_OF_DAYS} days</span>
				</div>
			);
		}
	} else if (!selectedClient || selectedClient?.label === '') {
		noClientSelected = true;
		warning = (
			<div className="warning-container">
				<GenesysDevIcon className="icon" icon={GenesysDevIcons.AppInfoSolid} />
				<span> Select a client to see details</span>
			</div>
		);
	}

	return (
		<div className="application-inspector">
			<DxItemGroup
				format="dropdown"
				items={dropdownItems}
				title="Clients"
				description="Select a client"
				onItemChanged={(item) => setSelectedClient(item)}
			/>

			<div>{resources ? validQueryResult && payload : !noClientSelected && <LoadingPlaceholder text="Loading client details" />}</div>
			{warning}
		</div>
	);
}

export default ApplicationInspector;
