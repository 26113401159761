import { ReactNode } from 'react';
import { DxButton } from 'genesys-react-components';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import { StructuredSubscription, TopicKey, NotificationMode } from '../../../helpers/CmsApiTypes';
import { GetTopicDisplayFancy, IsNotificationModeAllowed } from '../../../helpers/UIHelpers';
import Tag from '../../cards/Tag';

import './SubscriptionGroup.scss';

interface IProps {
	subscriptions: StructuredSubscription[];
	title?: string;
	description?: ReactNode;
	topicKey: TopicKey;
	onSelect: { (topicKey: TopicKey, sub: StructuredSubscription, mode: NotificationMode): void };
	onRemove: { (topicKey: TopicKey, sub: StructuredSubscription): void };
}

export default function SubscriptionGroup(props: IProps) {
	return (
		<div className="subscription-group">
			{props.title && (
				<div className="title">
					<h4>{props.title}</h4>
					{props.description && <p className="description">{props.description}</p>}
				</div>
			)}
			{props.subscriptions.map((sub) => (
				<div key={sub.topic} className="subscription-item">
					<span>{GetTopicDisplayFancy(sub, props.topicKey)}</span>
					{sub.mode === NotificationMode.None && <Tag>Not subscribed</Tag>}
					<div className="subscription-mode-group">
						<DxButton
							type={sub.mode === NotificationMode.Email ? 'primary' : 'secondary'}
							disabled={!IsNotificationModeAllowed(props.topicKey, NotificationMode.Email)}
							onClick={() => props.onSelect(props.topicKey, sub, NotificationMode.Email)}
						>
							Immediate
						</DxButton>
						<DxButton
							type={sub.mode === NotificationMode.DigestDaily ? 'primary' : 'secondary'}
							disabled={!IsNotificationModeAllowed(props.topicKey, NotificationMode.DigestDaily)}
							onClick={() => props.onSelect(props.topicKey, sub, NotificationMode.DigestDaily)}
						>
							Daily
						</DxButton>
						<DxButton
							type={sub.mode === NotificationMode.DigestWeekly ? 'primary' : 'secondary'}
							disabled={!IsNotificationModeAllowed(props.topicKey, NotificationMode.DigestWeekly)}
							onClick={() => props.onSelect(props.topicKey, sub, NotificationMode.DigestWeekly)}
						>
							Weekly
						</DxButton>
						<DxButton
							type={sub.mode === NotificationMode.Blast ? 'primary' : 'secondary'}
							disabled={!IsNotificationModeAllowed(props.topicKey, NotificationMode.Blast)}
							onClick={() => props.onSelect(props.topicKey, sub, NotificationMode.Blast)}
						>
							Blast
						</DxButton>
						<DxButton
							type={sub.mode === NotificationMode.App ? 'primary' : 'secondary'}
							disabled={!IsNotificationModeAllowed(props.topicKey, NotificationMode.App)}
							onClick={() => props.onSelect(props.topicKey, sub, NotificationMode.App)}
						>
							App Only
						</DxButton>
						<DxButton
							className="remove-button"
							type="secondary"
							onClick={() => props.onRemove(props.topicKey, sub)}
							disabled={sub.mode === NotificationMode.None}
						>
							<GenesysDevIcon icon={GenesysDevIcons.EditorTrash} />
						</DxButton>
					</div>
				</div>
			))}
			{props.subscriptions.length === 0 && (
				<div className="subscription-item">
					<em>No subscriptions</em>
				</div>
			)}
		</div>
	);
}
