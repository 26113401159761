import React from 'react';
import { DxButton } from 'genesys-react-components';
import { loginsEnabled } from '../../helpers/settings/SettingsManager';

export default function PublicLogin() {
	// Remove component if logins are not enabled
	if (!loginsEnabled) {
		window.location.replace('/');
		return <></>;
	}

	const login = () => {
		if (!window.location.pathname.toLowerCase().startsWith('/sessions/new')) {
			const loginUrl = process.env.REACT_APP_NEW_PUBLIC_SESSION_URL || '/sessions/public/new';
			window.location.assign(loginUrl);
		}
	};

	return (
		<div>
			<p>
				You are not logged in. Logging in to the Developer Center enables access to personalized content and features such as subscriptions
				to receive notifications when pages are updated. Look out for more features such as personalized search recommendations in the
				future.
			</p>
			<DxButton type="primary" onClick={login}>
				Sign In
			</DxButton>
		</div>
	);
}
