import React, { useEffect, useState } from 'react';

import CmsApi from '../../helpers/CmsApi';
import { DataTable, DataTableRow } from 'genesys-react-components';
import { DxButton, LoadingPlaceholder } from 'genesys-react-components';
import DxLink from '../dxlink/DxLink';

import './Changelog.scss';
import moment from 'moment';
import { addToast, ToastType } from '../../helpers/atoms/ToastAtom';
import { ChangelogData, ChangelogItem, ChangeType, RequestRoundInfo } from '../../types';

/**
 * Displays the most recent additions, removals, and changes to dx-ui pages.
 * Number of results to display is configurable on the page via user input.
 *
 * @returns changelog table
 */
export default function Changelog() {
	const [rowData, setRowData] = useState<DataTableRow[]>([]);
	const [nextKey, setNextKey] = useState<string>();
	const [shouldDisplayLoadMore, setShouldDisplayLoadMore] = useState<boolean>(true);
	const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		(async() => {
			if (shouldLoadMore) {
				if (!isLoading) setIsLoading(true);
				try {
					await loadRows();
				} catch (err: any) {
					console.error(err?.message || 'unknown error');
					addToast({ 
						toastType: ToastType.Critical,
						message: err?.message || 'unknown error',
					});
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldLoadMore]);

	async function loadRows() {
		/*
		 * If no relevant changelog items are retrieved in this round of requests, and there are more changelog items to retrieve,
		 * then automatically run the next round of requests without the "Show more" button being clicked.
		 * This object is used to track and store the criteria for that condition.
		 */
		const requestRoundInfo: RequestRoundInfo = {
			foundRelevantItems: false,
			areMorePotentialItems: false
		};

		const changelogData: ChangelogData | undefined = await CmsApi.listAssetHistory(requestRoundInfo, nextKey);

		if (changelogData?.nextKey) {
			setNextKey(changelogData.nextKey);
			// a next key was returned in the x-last-key header, so there are more potential changelog items
			requestRoundInfo.areMorePotentialItems = true;
		} else {
			// Reached the end of asset history, so there are no changelog items to load after the current round of queries.
			setShouldDisplayLoadMore(false);
		}
		// relevant changelog items were found
		if (changelogData?.changelogItems && changelogData?.changelogItems.length > 0) requestRoundInfo.foundRelevantItems = true;

		const newRowData: DataTableRow[] = (changelogData?.changelogItems || [])
			.map((item: ChangelogItem) => {
				const mdStripped: string = item.keyPath.endsWith('.md') ? item.keyPath.substring(0, item.keyPath.indexOf('.md')) : item.keyPath;
				const formattedKeyPath: string = mdStripped.startsWith('/') ? mdStripped : '/' + mdStripped;
				return {
					cells: [
						// The title will not be present if an item was removed, so display the keypath in the title column as plain text instead.
						{ content: item.title || renderPath(formattedKeyPath, ChangeType.REMOVED)},
						{ content: item.changeType },
						{
							content: item.timestamp,
							renderedContent: <span>{moment(item.timestamp).format('M/D/YYYY')}</span>
						},
						{
							content: formattedKeyPath,
							renderedContent: renderPath(formattedKeyPath, item.changeType)
						},
					],
				} as DataTableRow;
			})
			.filter((row: DataTableRow | undefined) => row !== undefined);
		if (rowData.length > 0) {
			setRowData([...rowData, ...newRowData]);
		} else {
			setRowData(newRowData);
		}

		if (!requestRoundInfo.foundRelevantItems && requestRoundInfo.areMorePotentialItems) {
			setShouldLoadMore(true);
			setIsLoading(true);
		} else {
			setShouldLoadMore(false);
			setIsLoading(false);
		}
	}

	// Returns plain text path for pages that were removed, otherwise returns a link
	function renderPath(keyPath: string, changeType: ChangeType): React.ReactNode {
		if (changeType === ChangeType.REMOVED) {
			return keyPath;
		} else {
			return <DxLink href={keyPath} title={keyPath}>{keyPath}</DxLink>
		}
	}

	return (
		<div>
			{rowData && (
				<DataTable
					filterable={true}
					sortable={true}
					headerRow={{
						cells: [{ content: 'Page Title' }, { content: 'Change Type' }, { content: 'Change Date' }, { content: 'Link' }],
					}}
					rows={rowData}
				/>
			)}
			{shouldDisplayLoadMore &&
				<DxButton
					onClick={async () => {
						/*
						 * Load rows and use the next key since this is not the first round of requests.
						 * Disable the show more button while the rows are being set.
						 */
						if (!isLoading) setIsLoading(true);

						try {
							await loadRows();
						} catch (err: any) {
							console.error(err?.message || 'unknown error');
							addToast({ 
								toastType: ToastType.Critical,
								message: err?.message || 'unknown error',
							});
						}
					}}
					disabled={isLoading}
				>
					Show More
				</DxButton>
			}
			{!rowData && <LoadingPlaceholder />}
		</div>
	);
}
