import { ContentPage, MatchType } from '../types';

// Content Components
// import Snake from '../components/content/snake/Snake';
// import Test from '../components/content/test/Test';
// import NavProviderExample from '../components/content/test/NavProviderExample';

// Use these values for the matchType property on a route to control how the path is matched
// export let MatchType = {
// 	exact: 'EXACT', // default
// 	startsWith: 'STARTSWITH',
// };

class ContentPages {
	[path: string]: ContentPage;

	constructor() {
		// Routes
		this['/me'] = {
			title: 'My Profile',
			component: 'MyProfile',
			matchType: MatchType.StartsWith,
		};

		this['/publiclogin'] = {
			title: 'Developer Center Login',
			component: 'PublicLogin',
			matchType: MatchType.StartsWith,
		};

		// this['/announcements'] = {
		// 	title: 'Announcements',
		// 	component: 'AnnouncementLayout',
		// 	matchType: MatchType.StartsWith,
		// };

		if (window.location.host.toLowerCase().startsWith('developer') || window.location.host.toLowerCase().startsWith('localhost')) {
			this['/forum/'] = {
				title: 'Developer Forum',
				component: 'ExternalLinkRefresher',
				matchType: MatchType.StartsWith,
			};
		}
	}
}

export default new ContentPages();
