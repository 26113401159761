import React from 'react';
import moment from 'moment';

import CmsApi from '../../helpers/CmsApi';
import NotificationSubscriptions from './subscriptions/NotificationSubscriptions';
import { loginsEnabled } from '../../helpers/settings/SettingsManager';

export default function MyProfile() {
	// Remove component if logins are not enabled
	if (!loginsEnabled) {
		window.location.replace('/');
		return <></>;
	}

	let lastVisit = '';
	if (CmsApi.currentUser?.lastLogin) {
		lastVisit = moment.duration(moment().diff(moment(CmsApi.currentUser?.lastLogin))).humanize();
	}
	return (
		<div>
			<p>
				Welcome {CmsApi.currentUser?.firstName || CmsApi.currentUser?.displayName || 'fellow human'}.{' '}
				{lastVisit &&
					`It has been 
				${lastVisit} since you last authenticated.`}
			</p>
			<h2>Notification Subscriptions</h2>
			<NotificationSubscriptions />
		</div>
	);
}
