import axios, { AxiosInstance, AxiosResponse, AxiosResponseHeaders, RawAxiosRequestHeaders } from 'axios';
import yaml from 'js-yaml';

import { FeedbackData } from '../components/feedback/FeedbackAtom';
import { ToastType, addToast } from './atoms/ToastAtom';
import {
	User,
	Asset,
	AssetContent,
	getHeader,
	FeedbackSentiment,
	FeedbackRequest,
	PagedNotificationContent,
	MarkReadRequest,
	SubscriptionBase,
	StructuredSubscriptions,
	SubscriptionRequest,
	UserStatus,
	ContentIndexType,
	AssetContentBlob,
	AssetContentString,
} from './CmsApiTypes';
import { SitemapData, Sitemap } from './Sitemap';
import { AuthorData, Announcement, ChangelogData, ChangelogItem, RequestRoundInfo } from '../types';

const JWT_KEY = 'yeti-ui-jwt';
const knownTLDRegex = /\.((?:test-|dev-)?genesys\.cloud)/i;

class CmsApi {
	api: AxiosInstance;
	isAuthorized: Boolean = false;
	jwt: string;
	currentUser?: User;
	baseURL: string | undefined;
	useCDN: boolean = process.env.REACT_APP_USE_ASSET_CDN === 'true';
	property: string = process.env.REACT_APP_SITE_PROPERTY || 'none';
	externalSite: boolean = process.env.REACT_APP_EXTERNAL_SITE === 'true';
	requiresAuthorization: boolean = process.env.REACT_APP_REQUIRES_AUTHORIZATION === 'true';

	cdnHost?: string;

	constructor() {
		// Scrape JWT from hash
		const hash = window.location.hash.substring(1); // remove the '#' symbol
		const params = new URLSearchParams(hash);
		const hashJwt = params.get('jwt');

		// Use the deployed CMS API host when the TLD is a known hostname
		const tld = this.getWindowTLD();
		if (tld) {
			this.baseURL = `https://yeticms-api.${tld}`;
		} else if (process.env.REACT_APP_CMS_API_BASE_URL) {
			// this env var should only be present when running locally
			this.baseURL = process.env.REACT_APP_CMS_API_BASE_URL;
		}

		// Clean up fragment
		if (hashJwt) {
			window.location.hash = '';
		}

		// Get JWT
		this.jwt = params.get('jwt') || localStorage.getItem(JWT_KEY) || '';
		var headers: RawAxiosRequestHeaders = {};
		if (this.jwt) {
			headers.Authorization = this.jwt;
		}

		// CDN logic
		const tldMatch = window.location.hostname.match(knownTLDRegex);
		if (this.useCDN && tldMatch) {
			this.cdnHost = `https://yeticms-api${tldMatch[0]}`;
		} else if (this.useCDN && process.env.REACT_APP_CMS_API_BASE_URL) {
			// this env var should only be present when running locally
			this.cdnHost = process.env.REACT_APP_CMS_API_BASE_URL;
		} else {
			this.useCDN = false;
		}

		// Configure axios instance
		this.api = axios.create({
			baseURL: this.baseURL,
			headers,
			validateStatus: () => true,
		});
	}

	getWindowTLD(): string | undefined {
		const matches: RegExpMatchArray | null = window.location.hostname.match(knownTLDRegex);
		if (matches) return matches[1];
		else return undefined;
	}

	async getAssets(keyPath?: string) {
		let requestPath = process.env.REACT_APP_PUBLIC_ASSETS_PATH || `/api/${this.property}/assets`;
		if (keyPath) requestPath += keyPath;
		requestPath += '?all=true';
		const res = await this.api.get<Asset[]>(requestPath);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching assets:', res.status, res.statusText);
		}
	}

	async listAssetHistory(requestRoundInfo: RequestRoundInfo, lastKey?: string): Promise<ChangelogData | undefined> {
		let unsortedItems: ChangelogItem[] = [];
		let nextKey;
		const useAuthenticatedRoute: boolean = this.requiresAuthorization || !!(await this.checkAuthorization());

		// Always get public changelog data for the current site.
		let requestPath: string;
		if (useAuthenticatedRoute) {
			requestPath = `/api/${this.property}/assethistory`;
		} else {
			requestPath = `/api/${this.property}/publicassethistory`;
		}

		if (lastKey) requestPath += `?lastKey=${encodeURIComponent(lastKey)}`;
		const res = await this.api.get<ChangelogItem[]>(requestPath);
		if (isSuccessful(res.status)) {
			unsortedItems.push(...res.data);
			nextKey = res.headers['x-last-key'];

			if (nextKey) {
				requestRoundInfo.areMorePotentialItems = true;
			}
		} else {
			console.error('Error fetching assets:', res.status, res.statusText);
		}

		// sort the changelog items by timestamp
		const sortedItems = unsortedItems.sort((a: ChangelogItem, b: ChangelogItem) => {
			if (a.timestamp > b.timestamp) return -1;
			if (a.timestamp < b.timestamp) return 1;
			return 0;
		});

		return {
			changelogItems: sortedItems,
			nextKey,
		};
	}

	async destroyCurrentSession() {
		let requestPath = '/api/session';
		const res = await this.api.delete(requestPath);
		if (isSuccessful(res.status) || res.status === 404) {
			this.jwt = '';
			localStorage.removeItem(JWT_KEY);
			return true;
		} else {
			console.error('Error deleting current session:', res.status, res.statusText);
			return false;
		}
	}

	async getSitemap() {
		return new Sitemap((await this.getIndex(ContentIndexType.Sitemap)) || ({} as SitemapData));
	}

	async getIndex(indexType: ContentIndexType) {
		// Default to private auth-required indexes
		let requestPath = `/api/${this.property}/index/${encodeURIComponent(indexType)}`;

		// Index URL overrides for public indexes
		if (this.externalSite) {
			// Check if we should use the API instead of CloudFront - this only gets set this way when running locally
			if (process.env.REACT_APP_USE_INDEX_API === 'true') {
				// Use unauthenticated API route to lambda
				requestPath = `/api/${this.property}/index/public/${encodeURIComponent(indexType)}`;
			} else {
				// Use unauthenticated public CloudFront route to S3
				requestPath = `/indexes/${this.property}/${encodeURIComponent(indexType)}-public.json`;
			}
		}

		const res = await this.api.get<SitemapData>(requestPath);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching content index:', res.status, res.statusText);
		}
	}

	async checkAuthorization() {
		// if a jwt is not present, the user is not authorized
		if (!this.jwt || this.jwt === '') return false;

		try {
			const res = await this.api.get<User>('/api/users/me');
			this.isAuthorized = res.status === 200;
			if (this.isAuthorized) this.currentUser = normalizeUser(res.data);
			// Save JWT
			if (this.isAuthorized) {
				localStorage.setItem(JWT_KEY, this.jwt);
			}
			return this.isAuthorized;
		} catch (err) {
			console.log('Error fetching user content ', err);
			return false;
		}
	}

	async getAssetContent(keypath: string, asBlob = false): Promise<AssetContent | undefined> {
		try {
			if (keypath.startsWith('/')) keypath = keypath.substring(1);

			// public assets are served by a different base URL
			let url: string;
			if (process.env.REACT_APP_PUBLIC_ASSETS_PATH) {
				url = `${process.env.REACT_APP_PUBLIC_ASSETS_PATH}/${decodeSlashes(encodeURIComponent(keypath))}?content=true`;
			} else {
				url = `/api/${this.property}/assets/${decodeSlashes(encodeURIComponent(keypath))}?content=true`;
			}

			const res = await this.api.get(url, {
				responseType: asBlob ? 'blob' : 'text',
			});

			if (isSuccessful(res.status)) {
				if (!res.data) return undefined;
				return { content: res.data, contentType: getHeader('content-type', res.headers as AxiosResponseHeaders) || '' };
			} else {
				return undefined;
			}
		} catch (err) {
			console.error('Error fetching draft content', err);
			return undefined;
		}
	}

	// Gets asset content from the API as a Blob
	async getAssetContentBlob(keypath: string): Promise<AssetContentBlob | undefined> {
		return this.getAssetContent(keypath, true) as Promise<AssetContentBlob | undefined>;
	}

	// Gets draft content from the API as a string
	async getAssetContentString(keypath: string): Promise<AssetContentString | undefined> {
		return this.getAssetContent(keypath, false) as Promise<AssetContentString | undefined>;
	}

	async postFeedback(feedbackdata: FeedbackData, sentiment: FeedbackSentiment) {
		const body: FeedbackRequest = {
			userId: this.currentUser?.userId || '',
			url: window.location.href,
			message: feedbackdata.message,
			sentiment,
			name: feedbackdata.name,
			email: feedbackdata.email,
		};
		const res = this.jwt !== '' ? await this.api.post(`/api/feedback`, body) : await this.api.post(`/api/feedback/public`, body);

		if (isSuccessful(res.status)) {
			return true;
		} else {
			logErrorResponse(res, 'Failed to submit feedback. ' + process.env['REACT_APP_FEEDBACK_FAIL_MESSAGE'], true);
			return false;
		}
	}

	async getNotifications(cursor?: string) {
		let requestPath = '/api/users/me/notifications';
		if (cursor) requestPath += `?cursor=${encodeURIComponent(cursor)}`;
		const res = await this.api.get<PagedNotificationContent>(requestPath);
		//HACK: suppress when disabled
		if (res.status === 501) return { data: [] } as PagedNotificationContent;
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching notifications:', res.status, res.statusText);
		}
	}

	async markNotificationRead(messageId: string, timestamp: number, pinnedUntil?: number) {
		return this.markNotificationsRead([{ messageId, timestamp, pinnedUntil }]);
	}

	async markNotificationsRead(requests: MarkReadRequest[]) {
		const res = await this.api.post<undefined>('/api/users/me/notifications/read', requests);
		//HACK: suppress when disabled
		if (res.status === 501) return;
		if (isSuccessful(res.status)) {
			return;
		} else {
			console.error('Error marking read:', res.status, res.statusText);
		}
	}

	async getSubscriptions() {
		const res = await this.api.get<SubscriptionBase[]>(`/api/users/me/subscriptions`);
		//HACK: suppress when disabled
		if (res.status === 501) return [] as SubscriptionBase[];
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error fetching subscriptions');
			return undefined;
		}
	}

	async getStructuredSubscriptions() {
		// don't try the request if no jwt is present
		if (!this.jwt || this.jwt === '') return {} as StructuredSubscriptions;

		const res = await this.api.get<StructuredSubscriptions>(`/api/users/me/subscriptions?structured=true`);
		//HACK: suppress when disabled
		if (res.status === 501) return {} as StructuredSubscriptions;
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error fetching subscriptions');
			return undefined;
		}
	}

	async patchSubscriptions(patchRequest: SubscriptionRequest[]) {
		const res = await this.api.patch<SubscriptionBase[]>(`/api/users/me/subscriptions`, patchRequest);
		//HACK: suppress when disabled
		if (res.status === 501) return [] as SubscriptionBase[];
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error updating subscriptions');
			return undefined;
		}
	}

	async patchStructuredSubscriptions(patchRequest: SubscriptionRequest[]) {
		const res = await this.api.patch<StructuredSubscriptions>(`/api/users/me/subscriptions?structured=true`, patchRequest);
		//HACK: suppress when disabled
		if (res.status === 501) return {} as StructuredSubscriptions;
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error updating subscriptions');
			return undefined;
		}
	}

	async getAuthorinfo(authorId: string) {
		const res = await this.api.get<AuthorData>(`/api/users/${encodeURIComponent(authorId)}/authorinfo`);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching author ', res);
			return undefined;
		}
	}

	async getData(pathWithSlug: string) {
		// Strip leading slash
		if (pathWithSlug.startsWith('/')) {
			pathWithSlug = pathWithSlug.substring(1);
		}
		// Encode for safety
		pathWithSlug = decodeSlashes(encodeURIComponent(pathWithSlug));

		// Make request
		const res = await this.api.get<Object | string>(`${this.cdnHost || ''}/data/${this.property}/${pathWithSlug}`);
		if (isSuccessful(res.status)) {
			if (res.headers['content-type'] === 'application/json') {
				return res.data as Object;
			} else if (res.headers['content-type'] === 'application/yaml') {
				return yaml.load(res.data as string) as Object;
			}
		} else {
			console.error('Error fetching data ', res);
			return undefined;
		}
	}

	async getAnnouncements(startDate?: number, endDate?: number) {
		let queryString = '';
		if (startDate && endDate) {
			queryString = `&startDate=${startDate}&endDate=${endDate}`;
		}
		const res = await this.api.get<Announcement[]>(`/api/announcements/published?visibility=internal${queryString}`);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching announcements ', res);
			return undefined;
		}
	}

	async getAnnouncement(announcementId: string) {
		const res = await this.api.get<Announcement>(`/api/announcements/published/${announcementId}`);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching announcement ', res);
			return undefined;
		}
	}

	async getPublicAnnouncements(startDate?: number, endDate?: number) {
		let queryString = '';
		if (startDate && endDate) {
			queryString = `&startDate=${startDate}&endDate=${endDate}`;
		}
		const res = await this.api.get<Announcement[]>(`/api/announcements/published/public?${queryString}`);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching announcements ', res);
			return undefined;
		}
	}

	async getPublicAnnouncement(announcementId: string) {
		const res = await this.api.get<Announcement>(`/api/announcements/published/public/${announcementId}`);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching announcement ', res);
			return undefined;
		}
	}
}

function isSuccessful(httpStatus: number) {
	return httpStatus >= 200 && httpStatus < 300;
}

function logErrorResponse(res: AxiosResponse, message?: string, noTimeout = false): void {
	console.log(res);
	console.error(`Error invoking ${res.config.method} ${res.config.url}:`, res.status, res.statusText, '\n', res.data, '\n', res);
	addToast({
		toastType: ToastType.Critical,
		title: 'API Error',
		message:
			message ||
			'An unexpected error has occurred fetching data from the API. Application functionality may be impacted. See the JavaScript console for details.',
		timeoutSeconds: noTimeout ? undefined : 30,
	});
}

export function decodeSlashes(str: string): string {
	if (!str) return str;
	return str.replaceAll('%2F', '/');
}

// normalizeUser mutates the user object to ensure defaults are set
export function normalizeUser(user: User): User {
	user.userId = user.userId || '00000000-0000-0000-0000-00000000000';
	user.firstName = user.firstName || 'Unknown';
	user.lastName = user.lastName || 'User';
	user.displayName = user.displayName || `${user.firstName} ${user.lastName}`;
	user.status = user.status || UserStatus.Active;
	user.email = user.email || 'unknown.user@genesys.com';
	user.permissions = user.permissions || [];
	return user;
}

export default new CmsApi();
