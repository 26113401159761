import { atom } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

const pageTitleAtom = atom({
	key: 'pageTitle',
	default: 'Page Title',
});

export function setPageTitle(title: string) {
	setRecoil(pageTitleAtom, title || 'Page Title');
}

export function usePageTitle() {
	return getRecoil(pageTitleAtom);
}
