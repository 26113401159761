import React from 'react';
import { Link } from 'react-router-dom';
import { GenesysDevIcons } from 'genesys-dev-icons';

import IconCard from './cardcontent/IconCard';
import PlainCard from './cardcontent/PlainCard';
import ImageCard from './cardcontent/ImageCard';
import { ContentIndexPage } from '../../helpers/Sitemap';

import './Card.scss';

export type CardStyles = 'plain' | 'icon' | 'icon-large' | 'image' | 'image-wide';
interface IProps {
	data: ContentIndexPage;
	cardStyle?: CardStyles;
	icon?: GenesysDevIcons;
	// onClickAction will override default link data
	onClickAction?: CardClickAction;
	maxTextHeight?: number;
}

export interface CardClickAction {
	(): void;
}

export default function Card(props: IProps) {
	const cardStyle: CardStyles = props.cardStyle || 'plain';

	let cardContent;
	switch (cardStyle) {
		case 'icon':
		case 'icon-large': {
			cardContent = (
				<IconCard
					data={props.data}
					icon={(props.data.icon || props.icon || GenesysDevIcons.AppQuestionSolid) as GenesysDevIcons}
					iconFormat={cardStyle === 'icon' ? 'small' : 'large'}
					onClickAction={props.onClickAction}
				/>
			);
			break;
		}
		case 'image':
		case 'image-wide': {
			cardContent = (
				<ImageCard
					data={props.data}
					orientation={cardStyle === 'image-wide' ? 'wide' : 'tall'}
					onClickAction={props.onClickAction}
					maxTextHeight={props.maxTextHeight}
				/>
			);
			break;
		}
		default: {
			cardContent = <PlainCard data={props.data} onClickAction={props.onClickAction} maxTextHeight={props.maxTextHeight} />;
		}
	}

	return <div className="dx-card">{cardContent}</div>;
}

export function WrapWithLink(content: any, link?: string, onClickAction?: CardClickAction) {
	if (onClickAction) {
		return (
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			<a
				href="#"
				onClick={(e) => {
					// Prevent the href click and propigate the event where we want to
					e.preventDefault();
					if (onClickAction) onClickAction();
				}}
			>
				{content}
			</a>
		);
	} else {
		return <Link to={link || ''}>{content}</Link>;
	}
}
