import React from 'react';
import { LoadingPlaceholder } from 'genesys-react-components';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
	DocumentNode,
	HeadingNode,
	ImageNode,
	YeastBlockNodeTypes,
	YeastChild,
	YeastInlineNodeTypes,
	YeastNode,
	scrapeText,
} from 'yeast-core';
import { MarkdownParser } from 'yeast-markdown-parser';
import { ReactRenderer } from 'yeast-react-renderer';

import ImageAsset from '../imageasset/ImageAsset';
import HeadingRendererPlugin from './plugins/HeadingRendererPlugin';
import LinkRendererPlugin from './plugins/LinkRendererPlugin';
import CustomComponentRendererPlugin, { YeastCustomNodesTypes } from './plugins/CustomComponentRendererPlugin';
import { Heading, setHeadings } from '../../../helpers/atoms/inPageHeadings';

import './MarkdownDisplay.scss';

interface IProps {
	markdown: string;
	skipScrapeHeadings?: boolean;
}

const customRenderers = {
	// Override headings to implement clickable links using Link from react-router-dom
	[YeastBlockNodeTypes.Heading]: HeadingRendererPlugin,
	// Override image renderer to use component to load image from API
	[YeastInlineNodeTypes.Image]: (node: YeastChild, renderer: ReactRenderer): ReactNode | undefined => {
		if (!Object.hasOwn(node, 'type') || (node as any).type.toLowerCase() !== YeastInlineNodeTypes.Image) return undefined;
		const imageNode = node as ImageNode;
		return <ImageAsset key={imageNode.src} src={imageNode.src} alt={imageNode.alt} title={imageNode.title} />;
	},
	// Override to use DxLink component
	[YeastInlineNodeTypes.Link]: LinkRendererPlugin,
	// Custom components
	[YeastCustomNodesTypes.OpenAPIExplorer]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.NotificationTool]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.AvailableMediaTypeTool]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.PostmanFiles]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.QuickHit]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.QuickHitListing]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.ScreenShare]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.SwaggerDiff]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.WebChatAndMessenger]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.PremiumAppSubmission]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.ApplicationInspector]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.BlogIndex]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.BlueprintIndex]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.Changelog]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.SdkDocExplorer]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.GuideIndex]: CustomComponentRendererPlugin,
	[YeastCustomNodesTypes.RelativeSitemap]: CustomComponentRendererPlugin,
};

const yeastRenderer = new ReactRenderer(customRenderers);
const yeastParser = new MarkdownParser();

export default function MarkdownDisplay(props: IProps) {
	/***
	 * DESIGN NOTE
	 * The parsed and rendered content is stored in a ref element to prevent re-running the rendering logic on each state update.
	 * When using the OOTB YeastNodeRenderer component, it re-runs renderComponents on every re-render. This causes all of the plugins
	 * to be re-run as well, resulting in entirely new objects being returned. The observed behavior is that any components that
	 * lazy-load additional information (e.g. images and OpenAPI Explorer) reset and reinitialize repeatedly.
	 */
	const renderedNodes = useRef<ReactNode>();
	const [renderTrigger, setRenderTrigger] = useState<number>(0);

	useEffect(() => {
		// Parse markdown into AST
		const document = yeastParser.parse(props.markdown) as DocumentNode;

		// Store AST in ref
		renderedNodes.current = yeastRenderer.renderComponents(document.children);

		// Scrape headings from AST
		if (!props.skipScrapeHeadings) {
			setHeadings(scrapeHeadings(document.children));
		}

		// Update render trigger to reload markdown
		setRenderTrigger(Date.now());
	}, [props.markdown, props.skipScrapeHeadings]);

	if (renderedNodes.current) {
		return <React.Fragment key={renderTrigger}>{renderedNodes.current}</React.Fragment>;
	} else {
		return <LoadingPlaceholder />;
	}
}

function scrapeHeadings(children: YeastChild[]): Heading[] {
	const nodes = children.filter((child) => Object.hasOwn(child, 'type')) as YeastNode[];
	let headings: Heading[] = [];
	nodes.forEach((node) => {
		if (node.type === YeastBlockNodeTypes.Heading) {
			// Add node as heading
			const headingNode = node as HeadingNode;
			headings.push({
				title: scrapeText(headingNode),
				link: headingNode.id,
				level: headingNode.level,
			});
		} else if (node.children) {
			// Scrape headings from child nodes
			headings = [...headings, ...scrapeHeadings(node.children)];
		}
	});
	return headings;
}
