import { atom, useRecoilValue } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import CmsApi from '../CmsApi';
import { NotificationMode, StructuredSubscriptions } from '../CmsApiTypes';
import { ToastType, addToast } from './ToastAtom';

const subscriptionsAtom = atom({
	key: 'subscriptionsAtom',
	default: (async () => {
		return CmsApi.getStructuredSubscriptions() || ({} as StructuredSubscriptions);
	})(),
});

export function useSubscriptions() {
	return useRecoilValue(subscriptionsAtom) || ({} as StructuredSubscriptions);
}

export async function addSubscription(topic: string, mode: NotificationMode) {
	const res = await CmsApi.patchStructuredSubscriptions([{ topic, mode }]);
	setRecoil(subscriptionsAtom, res);
	return res;
}

export async function removeSubscription(topic: string) {
	const res = await CmsApi.patchStructuredSubscriptions([{ topic, remove: true }]);
	setRecoil(subscriptionsAtom, res);
	return res;
}

export async function toggleSubscription(topic: string, mode: NotificationMode) {
	const remove = Object.values(getRecoil(subscriptionsAtom) || {})
		.flat()
		.some((s) => s?.topic === topic);

	// Call API and update state
	const res = await CmsApi.patchStructuredSubscriptions([{ topic, mode, remove }]);
	setRecoil(subscriptionsAtom, res);

	// Success toast (got a response and it's not empty)
	if (res && Object.keys(res).length > 0) {
		// On subscribe
		if (remove) {
			addToast({
				toastType: ToastType.Success,
				title: 'Page Subscription Removed',
				message: `You have removed your subscription to this page.`,
				link: '/me',
				linkText: 'Manage Subscriptions',
				timeoutSeconds: 15,
			});
		} else {
			addToast({
				toastType: ToastType.Success,
				title: 'Page Subscription Added',
				message: `You have subscribed to this page for ${mode} notifications. You can manage this setting in your profile.`,
				link: '/me',
				linkText: 'Manage Subscriptions',
				timeoutSeconds: 15,
			});
		}
	}
}
