import { ReactNode } from 'react';
import { YeastChild, LinkNode } from 'yeast-core';
import { NodeRendererPlugin, ReactRenderer, useKey } from 'yeast-react-renderer';

import DxLink from '../../../dxlink/DxLink';

const LinkRendererPlugin: NodeRendererPlugin = (node: YeastChild, renderer: ReactRenderer): ReactNode => {
	const key = useKey();

	const typedNode = node as LinkNode;
	return (
		<DxLink key={key.current} href={typedNode.href} title={typedNode.title} forceNewTab={typedNode.forceNewTab}>
			{renderer.renderComponents(typedNode.children) as ReactNode}
		</DxLink>
	);
};

export default LinkRendererPlugin;
