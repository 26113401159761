import { useEffect, useState } from 'react';
import CmsApi from '../../helpers/CmsApi';
import { AuthorData } from '../../types';
import { defaultAuthorImage, yetiCmsApiHost } from '../../helpers/helpers';
import InlinePlaceholder from '../newloadingplaceholder/InlinePlaceholder';

import './AuthorInfo.scss';

interface IProps {
	authorId: string;
	className?: string;
	mode?: 'full' | 'mini' | 'micro';
}

function AuthorInfo(props: IProps) {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [author, setAuthor] = useState<AuthorData>();
	const [authorImage, setAuthorImage] = useState<string>();

	useEffect(() => {
		(async () => {
			setIsLoaded(false);
			const authorData = await CmsApi.getAuthorinfo(props.authorId);
			setIsLoaded(true);
			if (authorData) {
				setAuthor(authorData);
				if (authorData.profileImage?.hasUploadedImage) {
					setAuthorImage(`${yetiCmsApiHost}/profileimages/${props.authorId}.png`);
				} else {
					setAuthorImage(defaultAuthorImage);
				}
			}
		})();
	}, [props.authorId]);

	if (!isLoaded)
		return (
			<InlinePlaceholder
				className={props.mode ? `author-info-placeholder ${props.mode}` : ''}
				width={props.mode === 'micro' ? '28px' : '90px'}
				height="28px"
			/>
		);

	if (!author || !authorImage) {
		return <></>;
	}

	// mode: micro
	if (props.mode === 'micro') {
		return (
			<div className="author-info micro">
				<img src={authorImage} className="author-image" alt={`profile of ${author?.displayName}`} />
			</div>
		);
	}

	// mode: mini
	if (props.mode === 'mini') {
		return (
			<div className="author-info mini">
				<img src={authorImage} className="author-image" alt={`profile of ${author?.displayName}`} />
				<span className="author-name" title={author.displayName}>
					{author.displayName}
				</span>
			</div>
		);
	}

	// default mode: full
	const subtitle = `${author.title ? author.title + ', ' : ''}${author?.organization || ''}`;
	return (
		<div className="author-info full">
			<img src={authorImage} className="author-image" alt={`profile of ${author?.displayName}`} />
			<div className="author-content">
				<p>
					<span className="author-name" title={author.displayName}>
						{author.displayName}
					</span>
					<br />
					<span className="author-subtitle" title={subtitle}>
						{subtitle}
					</span>
				</p>
			</div>
		</div>
	);
}

export default AuthorInfo;
