import { ContentsquareCollector } from './ContentsquareCollector';
import { DataCollectorEvent, ICollectorClass } from './DataCollectorTypes';
import { RumCollector } from './RumCollector';

// DataCollectorClass is the primary construct to fan out invocations to all registered collectors
class DataCollectorClass implements ICollectorClass {
	collectors: ICollectorClass[];

	constructor() {
		// Seed injector list
		this.collectors = [RumCollector, ContentsquareCollector];
	}

	// init initializes the injectors
	public init(): void {
		this.collectors.forEach((collector) => collector.init());
	}

	// event records an event for all collectors
	public event(eventType: DataCollectorEvent, eventData?: object) {
		this.collectors.forEach((collector) => collector.event(eventType, eventData));
	}
}

export const DataCollector = new DataCollectorClass();
