import React, { useState } from 'react';
import { authorizeNewAccount } from '../../helpers/atoms/AccountsAtom';
import { enumKeys, Region, RegionShortnames } from '../../helpers/platformapi/Region';
import { BooleanChangedCallback, DxButton, DxToggle } from 'genesys-react-components';
import { showLowerEnvs } from '../../helpers/settings/SettingsManager';

import './AddAccountSelector.scss';

export default function AddAccountSelector() {
	const [doForceAuthentication, setDoForceAuthentication] = useState(false);

	let regionButtons = [];
	for (const region of enumKeys(Region)) {
		const regionMember = Region[region];
		if (!showLowerEnvs && (regionMember === Region.dev || regionMember === Region.test)) continue;
		regionButtons.push(
			<DxButton key={region} type="secondary" onClick={() => authorizeNewAccount(regionMember, doForceAuthentication)}>
				{RegionShortnames.get(regionMember)}
			</DxButton>
		);
	}

	return (
		<div className="add-account-selector">
			<div className="region-selection">
				<span className="title">Select a region</span>
				<div className="region-list">{regionButtons}</div>
			</div>
			<DxToggle
				label="Prompt for login?"
				description="Enable this setting to forcibly be prompted for credentials. Use this to add a new account in a region in which you're already sign in."
				onChange={setDoForceAuthentication as BooleanChangedCallback}
				initialValue={doForceAuthentication}
			/>
		</div>
	);
}
