import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DxAccordion, DxItemGroup, DxButton, DxItemGroupItem, LoadingPlaceholder } from 'genesys-react-components';
import axios, { CancelTokenSource } from 'axios';
import { GenesysDevIcons, GenesysDevIcon } from 'genesys-dev-icons';
import { AlertBlock } from 'genesys-react-components';

import { ChatData } from '../../../types';
import AppSettings from '../../../helpers/settings/AppSettings';
import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import CodeFence from '../../codefence/CodeFence';
import Tooltip from '../../tooltip/Tooltip';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import ChatDataForm from './ChatDataForm';
import { SavedWebChatData, WebChatSettingsData } from '../../../types';
import { Models } from '../../../helpers/platformapi/PlatformAPITypes';
import { WebchatVersion } from './WebChatV1';
import { loadDeployments, loadQueues, createDeployment } from './helperFunctions';

import './WebChatV2.scss';

interface ChatConfigV2 {
	transport: Transport;
	userData?: ChatData;
}

interface IframeParameters {
	region?: string;
	advancedConfig?: string;
	chatData?: string;
}

interface Transport {
	type: string;
	dataURL: string;
	deploymentKey: string;
	orgGuid?: string;
	interactionData: RoutingData;
}

interface RoutingData {
	routing: Routing;
}

interface Routing {
	targetType: string;
	targetAddress: string;
	priority: number;
}

function WebChatV2() {
	const [configDisabled, setConfigDisabled] = useState(false);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const [queueItems, setQueueItems] = useState<DxItemGroupItem[]>([]);
	const [deploymentItems, setDeploymentItems] = useState<DxItemGroupItem[]>([]);
	const [chatInitiated, setChatInitiated] = useState(false);
	const [selectedDeployment, setSelectedDeployment] = useState('');
	const [selectedQueue, setSelectedQueue] = useState('');
	const [permissions, setPermissions] = useState('');
	const [loadingDeployments, setLoadingDeployments] = useState(false);
	const [loadingConfigData, setLoadingConfigData] = useState(true);
	const [chatDataToBeSaved, setChatDataToBeSaved] = useState<ChatData>({});
	const [userChatData, setUserChatData] = useState<ChatData>({});
	const [iframeUrlParam, setIframeUrlParam] = useState<IframeParameters>({});
	const savedSettings = useRecoilValue(AppSettings.webChatSettingsAtom());
	const savedData = useRecoilValue(AppSettings.webChatDataAtom());
	const IFrameRef = useRef<any>(null);
	const cancelToken = useRef<CancelTokenSource | undefined>();

	useEffect(() => {
		if (!selectedAccount) return;

		resetConfiguration();

		let permissionsErr = '';

		const tokenSource = axios.CancelToken.source();
		cancelToken.current = tokenSource;

		if (!selectedAccount.me?.authorization) {
			addToast({
				title: 'Authorization Error',
				message:
					'Unable to validate permissions. You may not see deployments or queues in the dropdowns. You\'re probably missing the permission "authorization:grant:view".',
				toastType: ToastType.Critical,
			});
			return;
		}

		let hasReadDeployment = false;
		let hasViewQueues = false;

		selectedAccount.me?.authorization?.permissions?.forEach((p: string) => {
			// Must check for startswith because of how permissions look with divisions. Can't use exact match here.
			if (p.startsWith('webchat:deployment:read')) hasReadDeployment = true;
			if (p.startsWith('routing:queue:view')) hasViewQueues = true;
		});

		// Set error messages
		if (!hasReadDeployment) permissionsErr += 'webchat:deployment:read,\n';
		if (!hasViewQueues) permissionsErr += 'routing:queue:view';
		setPermissions(permissionsErr);

		setLoadingConfigData(true);

		/**Wait for both deployments and queues to load and process results
		 If there are saved config then they gets applied, if not user has to select new config**/
		Promise.all([loadDeployments(cancelToken.current, WebchatVersion.TWO), loadQueues(cancelToken.current)])
			.then((res: any) => {
				const deployments = res[0];
				const queues = res[1];

				let deploymentItems: DxItemGroupItem[] = deployments.map((d: Models.WebChatDeployment) => {
					return { label: d.name, value: d.id };
				});

				let queueItems: DxItemGroupItem[] = queues.map((q: Models.Queue) => {
					return { label: q.name, value: q.id };
				});

				if (savedSettings.version2 && savedSettings.version2.selectedQueue !== '' && savedSettings.version2.selectedDeployment !== '') {
					deploymentItems = [];
					queueItems = [];

					deployments.forEach((d: Models.WidgetDeployment) => {
						if (d.name && d.id) {
							if (savedSettings.version2.selectedDeployment === d.id) {
								deploymentItems.push({ label: d.name, value: d.id, isSelected: true });
								return;
							}
							deploymentItems.push({ label: d.name, value: d.id });
						}
					});

					if (deploymentItems.length === 0 || !deploymentItems.find((d) => d.isSelected)) {
						deploymentItems.unshift({ label: '--- Select a deployment ---', value: '', disabled: true, isSelected: true });
						setDeploymentItems(deploymentItems);
					} else {
						setSelectedDeployment(savedSettings.version2.selectedDeployment || '');
					}

					queues.forEach((q: Models.Queue) => {
						if (q.name && q.id) {
							if (savedSettings.version2.selectedQueue === q.name) {
								queueItems.push({ label: q.name, value: q.id, isSelected: true });
								return;
							}
							queueItems.push({ label: q.name, value: q.id });
						}
					});

					if (queueItems.length === 0 || !queueItems.find((q) => q.isSelected)) {
						queueItems.unshift({ label: '--- Select a queue ---', value: '', disabled: true, isSelected: true });
						setQueueItems(queueItems);
					} else {
						setSelectedQueue(savedSettings.version2.selectedQueue || '');
					}

					setDeploymentItems(deploymentItems);
					setQueueItems(queueItems);
				} else {
					deploymentItems.unshift({ label: '--- Select a deployment ---', value: '', disabled: true, isSelected: true });
					setDeploymentItems(deploymentItems);

					queueItems.unshift({ label: '--- Select a queue ---', value: '', disabled: true, isSelected: true });
					setQueueItems(queueItems);
				}
				setLoadingConfigData(false);
			})
			.catch((err) => {
				if (!axios.isCancel(err)) {
					addToast({ title: 'Unable to get deployments/queues', message: err.message, toastType: ToastType.Critical });
					setLoadingConfigData(false);
				}
			});

		window.addEventListener('message', getIframeEvents);

		return () => {
			cancelToken.current?.cancel('Component unmounted');
			window.removeEventListener('message', getIframeEvents);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccount]);

	if (!selectedAccount) {
		return (
			<div className="warning-container">
				<GenesysDevIcon className="icon" icon={GenesysDevIcons.AppInfoSolid} />
				<span> Please add an account to utilize this tool</span>
			</div>
		);
	}

	const getIframeEvents = (event: any) => {
		const message = event.data.message;
		if (message === 'iframe-error') {
			addToast({ title: 'Error starting chat', message: event.data.value.message, toastType: ToastType.Critical });
			setChatInitiated(false);
			setConfigDisabled(false);
		} else if (message === 'iframe-event') {
			setChatInitiated(false);
			setConfigDisabled(false);
		}
	};

	async function createNewDeployment() {
		try {
			setLoadingDeployments(true);
			endChat();
			const newDeployment = (await createDeployment(cancelToken.current, WebchatVersion.TWO)) as Models.WidgetDeployment;

			const deployments: Models.WidgetDeployment[] = (await loadDeployments(
				cancelToken.current,
				WebchatVersion.TWO
			)) as Models.WidgetDeployment[];

			//Filter the new deployment out
			const filteredDeployments = deployments.filter((d: Models.WidgetDeployment) => d.id !== newDeployment.id);

			const deploymentItems: DxItemGroupItem[] = filteredDeployments.map((deployment: Models.WidgetDeployment) => {
				return { label: deployment.name || '', value: deployment.id || '' };
			});

			deploymentItems.unshift({ label: newDeployment.name || '', value: newDeployment.id || '', isSelected: true });

			setDeploymentItems(deploymentItems);

			setSelectedDeployment(newDeployment.id || '');

			setLoadingDeployments(false);

			addToast({
				title: 'New web chat deployment created',
				toastType: ToastType.Success,
				message: `Created webchat deployment: ${newDeployment.name}`,
			});
		} catch (error: any) {
			setLoadingDeployments(false);
			addToast({ title: 'Failed to create new deployment', toastType: ToastType.Critical, message: error.message });
		}
	}

	const stringifyChatData = (mode?: string) => {
		let v2ChatData = { ...userChatData };
		delete v2ChatData.firstName;
		delete v2ChatData.lastName;
		delete v2ChatData.email;
		delete v2ChatData.subject;
		delete v2ChatData.customAttr;
		delete v2ChatData.locale;

		let chatConfig: ChatConfigV2 = {
			transport: {
				type: 'purecloud-v2-sockets',
				dataURL: `https://api.${selectedAccount?.region}`,
				deploymentKey: selectedDeployment,
				orgGuid: selectedAccount?.me?.organization?.id,
				interactionData: {
					routing: {
						targetType: 'QUEUE',
						targetAddress: selectedQueue,
						priority: 2,
					},
				},
			},
			userData: v2ChatData,
		};

		if (mode) {
			return JSON.stringify(chatConfig, null, 2).replace(/\n/gim, '\n ');
		}
		return JSON.stringify({ widgets: { webchat: chatConfig } }, null, 2).replace(/\n/gim, '\n ');
	};

	const advancedConfig = () => {
		const config: any = {
			form: {
				autoSubmit: false,
				firstname: userChatData.firstName,
				lastname: userChatData.lastName,
				email: userChatData.email,
				subject: '',
			},
			formJSON: {
				wrapper: '<table></table>',
				inputs: [
					// Default fields
					{
						id: 'cx_webchat_form_firstname',
						name: 'firstname',
						maxlength: '100',
						placeholder: 'Required',
						label: 'First Name',
					},
					{
						id: 'cx_webchat_form_lastname',
						name: 'lastname',
						maxlength: '100',
						placeholder: 'Required',
						label: 'Last Name',
					},
					{
						id: 'cx_webchat_form_email',
						name: 'email',
						maxlength: '100',
						placeholder: 'Optional',
						label: 'Email',
					},
					{
						id: 'cx_webchat_form_subject',
						name: 'subject',
						maxlength: '100',
						placeholder: 'Optional',
						label: 'Subject',
					},
				],
			},
		};

		const customAttributes = userChatData['customAttr'] || [];
		for (let attribute of customAttributes) {
			if (attribute.name !== '') {
				config.formJSON.inputs.push({
					id: `cx_webchat_form_${attribute.name.toLowerCase().replace(/[^a-z0-9]/gi, '_')}`,
					name: attribute.name.replace(/\s|[^a-z0-9-_]/gi, ''),
					maxlength: '100',
					placeholder: 'Custom data placeholder',
					label: attribute.name,
					value: attribute.value,
				});
			}
		}

		return JSON.stringify(config, null, 2).replace(/\n/gim, '\n    ');
	};

	const endChat = () => {
		if (!IFrameRef.current) return;
		IFrameRef.current.contentWindow.postMessage({ message: 'end-chat-session', value: 'kill chat' }, '*');
	};

	function startChat() {
		if (selectedDeployment === '' || selectedQueue === '') {
			addToast({
				title: 'Unable to start chat ',
				message: 'Missing configuration(s): please select a deployment and queue',
				toastType: ToastType.Critical,
			});
			return;
		}

		const newIframeParamters: IframeParameters = {
			region: selectedAccount?.region,
			advancedConfig: advancedConfig(),
			chatData: stringifyChatData('urlParam'),
		};

		endChat();
		setIframeUrlParam(newIframeParamters);
		setChatInitiated(true);
		setConfigDisabled(true);
		saveData();
	}

	function saveData() {
		const newSettings: WebChatSettingsData = {
			selectedDeployment: selectedDeployment,
			selectedQueue: selectedQueue,
		};

		AppSettings.setWebChatSettings(newSettings, WebchatVersion.TWO);

		if (!chatDataToBeSaved.firstName || !chatDataToBeSaved.lastName) {
			return;
		}
		let savedChatData = [...savedData];

		let newData: SavedWebChatData = {
			id: chatDataToBeSaved.id || '',
			name: `${chatDataToBeSaved.lastName}-${chatDataToBeSaved.firstName}`,
			data: chatDataToBeSaved,
		};

		const dataExists = savedChatData.find((sd: SavedWebChatData) => sd.id === newData.id);

		if (dataExists) {
			const updatedData = savedChatData.filter((sd) => sd.id !== newData.id);
			updatedData.unshift(newData);
			AppSettings.setWebChatData(updatedData);
			return;
		}

		savedChatData.unshift(newData);

		AppSettings.setWebChatData(savedChatData);
	}

	function updateChatData(chatData: ChatData) {
		let temp = { ...chatData };
		setChatDataToBeSaved({ ...chatData });
		delete temp['id'];
		setUserChatData(temp);
	}

	function resetConfiguration() {
		setChatInitiated(false);
		setConfigDisabled(false);
		endChat();
	}

	const config = `<script>
window._genesys = ${stringifyChatData()};

function getAdvancedConfig(){
	return ${advancedConfig()}
}
const customPlugin = CXBus.registerPlugin('Custom');
</script>

<button type="button" id="chat-button" onclick="customPlugin.command('WebChat.open', getAdvancedConfig());">Start Chat</button>
`;

	const scriptTag = `<script 
	src="https://apps.${selectedAccount?.region}/widgets/9.0/cxbus.min.js" 
	onload="javascript:CXBus.configure({debug:false,pluginsPath:'https://apps.${selectedAccount?.region}/widgets/9.0/plugins/'}); CXBus.loadPlugin('widgets-core');">
</script>`;

	return (
		<div className="webchat-two-container">
			<div>
				To get started, follow these instructions:
				<ul>
					<li>Ensure that you are a member of a queue and also on-queue.</li>
					<li>
						Select a widget deployment or create a new one if there is none available. You can create a new deployment by using the button
						beside the deployments dropdown.
					</li>
					<li>Select a queue that you are currently on-queue.</li>
					<li>
						After selecting preferred configurations, you can go ahead and fill the chat data forms but that is not required. You can also
						use the populate fields button to fill the forms with random user data.
					</li>
					<li>Click the start chat button to initiate chat.</li>
					<li>
						Note that if chat data is provided, it will be automatically saved when the chat gets started and applied when the page
						refreshes. Chat data will not be saved if first name and last name are not present{' '}
					</li>
				</ul>
				See the <a href="https://developer.genesys.cloud/commdigital/digital/webchat/widget-version2">widget - version 2 </a>documentation
				for more details.
			</div>
			<DxAccordion title="Chat configuration (required)" showOpen={true}>
				{permissions ? (
					<AlertBlock
						title="You do not have the required permission(s) to continue to use this tool"
						alertType="critical"
						children={`Missing Permission(s): ${permissions}`}
					/>
				) : (
					<>
						{loadingConfigData || loadingDeployments ? (
							<LoadingPlaceholder text="Loading Chat Config" />
						) : (
							<React.Fragment>
								<div className="chat-configuration">
									<div>
										<DxItemGroup
											format="dropdown"
											items={deploymentItems}
											title="Deployments"
											description="Select a deployment or create a new one"
											onItemChanged={(item: DxItemGroupItem) => setSelectedDeployment(item.value)}
											disabled={configDisabled}
										/>
									</div>

									<div className="new-deployment-icon-container">
										<Tooltip text="Create New deployment">
											<GenesysDevIcon className="new-deployment-icon" onClick={createNewDeployment} icon={GenesysDevIcons.AppPlusStroke} />
										</Tooltip>
									</div>

									<div>
										<DxItemGroup
											format="dropdown"
											items={queueItems}
											title="Queues"
											description="Select a queue"
											onItemChanged={(item: DxItemGroupItem) => setSelectedQueue(item.label)}
											disabled={configDisabled}
										/>
									</div>
								</div>
							</React.Fragment>
						)}
					</>
				)}
			</DxAccordion>

			<ChatDataForm onValueUpdated={updateChatData} />

			<div className="start-chat-button-container">
				<DxButton className="start-chat-button" onClick={startChat} disabled={!!permissions}>
					Start Chat
				</DxButton>
			</div>
			<DxAccordion title="Deployment Script Tag">
				<CodeFence title="Script Tag" value={scriptTag} language="html" />
			</DxAccordion>
			<DxAccordion title="Chat Code">
				<CodeFence title="Chat Code" value={config} language="html" />
			</DxAccordion>

			<iframe
				id="webchat2-iframe"
				className={chatInitiated ? '' : 'hide-iframe'}
				src={`/webchatV2.html?env=${iframeUrlParam.region}&advancedConfig=${encodeURIComponent(
					iframeUrlParam.advancedConfig || ''
				)}&chatData=${encodeURIComponent(iframeUrlParam.chatData || '')}`}
				title="Webchat"
				ref={IFrameRef}
			></iframe>
			<DxButton onClick={resetConfiguration} className={`resetConfigButton ${chatInitiated ? '' : 'hide-iframe'}`}>
				Reset Configuration
			</DxButton>
		</div>
	);
}

export default WebChatV2;
