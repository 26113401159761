import { DataCollectorEvent, ContentsquareConfig, ICollectorClass } from './DataCollectorTypes';

interface ContentSquareUXA {
	push: { (event: [action: string, ...params: any]): void };
}

declare global {
	interface Window {
		_uxa: ContentSquareUXA;
	}

	var CS_CONF: ContentsquareConfig | undefined;
}

/***
 * Contentsquare docs
 * https://docs.contentsquare.com/en/web/
 */
class ContentsquareCollectorClass implements ICollectorClass {
	public init(): void {
		try {
			window._uxa = window._uxa || [];

			const contentsquareId = process.env.REACT_APP_CONTENT_SQUARE_ID;
			if (!contentsquareId) return;

			if (typeof CS_CONF === 'undefined') {
				// Initial event
				window._uxa.push(['setPath', window.location.pathname + window.location.hash.replace('#', '?__')]);

				// Create script element
				var contentsquareScript = document.createElement('script');
				contentsquareScript.type = 'text/javascript';
				contentsquareScript.async = true;
				contentsquareScript.src = '//t.contentsquare.net/uxa/ae783fcfeefe4.js';

				// Inject script element
				document.getElementsByTagName('head')[0].appendChild(contentsquareScript);
			} else {
				// Script has already been loaded, send normal pageview
				window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
			}
		} catch (error) {
			console.log(error);
		}
	}

	public event(eventType: DataCollectorEvent, eventData?: object) {
		window._uxa.push(['trackDynamicVariable', { key: eventType, value: eventData }]);
	}
}

export const ContentsquareCollector = new ContentsquareCollectorClass();
