import { atom, useRecoilValue } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

export interface FeedbackData {
	message: string;
	name?: string;
	email?: string;
}

// This atom use used by the feedback form so the data isn't lost on accidental closure
const feedbackAtom = atom<FeedbackData>({
	key: 'feedback-atom',
	default: { message: '' },
});

export function useFeedback() {
	return useRecoilValue(feedbackAtom);
}

export function setFeedback(feedbackVal: string, inputType: string = 'message') {
	const newFeedback = structuredClone(getRecoil(feedbackAtom));
	switch (inputType) {
		case 'message':
			newFeedback.message = feedbackVal;
			break;
		case 'email':
			newFeedback.email = feedbackVal;
			break;
		case 'name':
			newFeedback.name = feedbackVal;
	}
	setRecoil(feedbackAtom, newFeedback);
}
