import { Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import { LoadingPlaceholder } from 'genesys-react-components';
import AnnouncementCalendarView from './AnnouncementCalendarView';
import AnnouncementView from './AnnouncementView';

export default function AnnouncementLayout() {
	return (
		<Suspense fallback={<LoadingPlaceholder text="Loading announcements" />}>
			<Routes>
				<Route path="/announcements" element={<AnnouncementCalendarView />} />
				<Route path="/announcements/:announcementId" element={<AnnouncementView />} />
			</Routes>
		</Suspense>
	);
}
