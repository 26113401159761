import { atom, useRecoilValue } from 'recoil';

import CmsApi from '../CmsApi';
import { Sitemap, SitemapData } from '../Sitemap';

const requiresAuthorization = process.env.REACT_APP_REQUIRES_AUTHORIZATION === 'true';

export const sitemapAtom = atom({
	key: 'sitemap',
	default: (async () => {
		if (requiresAuthorization) {
			const isAuthorized = await CmsApi.checkAuthorization();
			if (!isAuthorized) {
				// User is not authorized, but the site requires it. Redirect to start a new session.
				if (!window.location.pathname.toLowerCase().startsWith('/sessions/new')) {
					window.location.assign(process.env.REACT_APP_NEW_SESSION_URL || '/sessions/new');
				}
			}
		}

		return (await CmsApi.getSitemap()) || new Sitemap({} as SitemapData);
	})(),
});

// Sitemap hook
export const useSitemap = () => useRecoilValue(sitemapAtom);
