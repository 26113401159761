// import { GenesysDevIcon, GenesysDevIcons } from "genesys-dev-icons";
import CmsApi from "../../helpers/CmsApi";
import './PublicLogout.scss';
import { ToastType, addToast } from "../../helpers/atoms/ToastAtom";

export default function PublicLogout() {
    // logs out the current user
    const logout = async(e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        // destroy the session
        const ok: boolean = await CmsApi.destroyCurrentSession();
        if (!ok) {
            addToast({ toastType: ToastType.Critical, message: 'Failed to logout current user. Please try again later.'});
        }
        // redirect to the landing page
        window.location.assign(window.location.origin)
    };

    return (
        <div className="public-logout-container">
            <a href="." onClick={logout}>Logout</a>
        </div>
    );
}