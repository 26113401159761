import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { CodeFence, DxButton } from 'genesys-react-components';
import saveAs from 'file-saver';

import AssetLoader from '../../../helpers/AssetLoader';
import { AssetContentString } from '../../../helpers/CmsApiTypes';
import CmsApi from '../../../helpers/CmsApi';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';

interface IProps {
	url: string;
}

const previewAllowlist = ['.json', '.yaml', '.txt', '.script', '.tf', '.csv'];

export default function FileDownloadWidget(props: IProps) {
	const assetUrl = useRef<string>('');
	const [content, setContent] = useState<AssetContentString>();
	const [isSaving, setIsSaving] = useState(false);
	const filename = props.url.split('/').pop();

	useEffect(() => {
		// Debounce duplicate updates
		if (assetUrl.current === props.url) return;
		assetUrl.current = props.url;

		// Only load certain file extensions
		if (!previewAllowlist.includes(`.${props.url.split('.').pop()}`)) return;

		// Get contents (note: the API function sanitizes the keypath, so be sure it doesn't already contain encoded characters, e.g. spaces)
		CmsApi.getAssetContentString(decodeURIComponent(props.url))
			.then((res) => setContent(res))
			.catch(console.error);
	}, [props.url]);

	let downloadButton: ReactNode;
	if (CmsApi.externalSite) {
		downloadButton = (
			<a href={AssetLoader.makeAssetURL(props.url)} download target="_blank" rel="noopener noreferrer">
				{props.url}
			</a>
		);
	} else {
		downloadButton = (
			<DxButton
				type="link"
				onClick={async () => {
					setIsSaving(true);

					// Lazy-fetch content when clicked
					const content = await CmsApi.getAssetContentBlob(decodeURIComponent(props.url));
					if (!content) {
						addToast({
							title: 'Failed to download file',
							message:
								'The file does not exist. If you believe this is a valid address, please report this issue using the feedback form at the bottom of the page.',
							timeoutSeconds: 15,
							toastType: ToastType.Critical,
						});
						setIsSaving(false);
						return;
					}

					// Trigger file save
					saveAs(content.content, filename);
					setIsSaving(false);
				}}
				disabled={isSaving}
			>
				{isSaving ? 'Downloading...' : filename}
			</DxButton>
		);
	}

	return (
		<div>
			<p>Click to download file: {downloadButton}</p>
			{content && (
				<div>
					<CodeFence title="Content Preview" noCollapse value={content.content} language={getHighlightLanguage(content.contentType)} />
				</div>
			)}
		</div>
	);
}

function getHighlightLanguage(contentType: string): string {
	switch (contentType) {
		case 'application/json':
			return 'json';
		case 'application/yaml':
			return 'yaml';
		default:
			return '';
	}
}
