import React from 'react';
import { AlertBlock, LoadingPlaceholder } from 'genesys-react-components';

import SubscriptionGroup from './SubscriptionGroup';
import { addSubscription, removeSubscription, useSubscriptions } from '../../../helpers/atoms/SubscriptionsAtom';
import { NotificationMode, StructuredSubscription, TopicKey } from '../../../helpers/CmsApiTypes';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import { GetTopicStandaloneDisplayString } from '../../../helpers/UIHelpers';

const property = process.env['REACT_APP_SITE_PROPERTY'] || '';
const siteName = process.env['REACT_APP_SITE_NAME'] || '';

export default function NotificationSubscriptions() {
	const subscriptions = useSubscriptions();

	if (subscriptions === undefined) return <LoadingPlaceholder text="Loading subscriptions" />;

	const setMode = async (topicKey: TopicKey, sub: StructuredSubscription, mode: NotificationMode) => {
		if ((await addSubscription(sub.topic, mode)) !== undefined) {
			addToast({
				title: 'Subscription Updated',
				toastType: ToastType.Success,
				message: `${GetTopicStandaloneDisplayString(sub, topicKey)} has been added to your ${modeDisplay(mode)}`,
				timeoutSeconds: 15,
			});
		}
	};
	const removeTopic = async (topicKey: TopicKey, sub: StructuredSubscription) => {
		if (removeSubscription(sub.topic) !== undefined) {
			addToast({
				title: 'Subscription Removed',
				toastType: ToastType.Success,
				message: `${GetTopicStandaloneDisplayString(sub, topicKey)} has been removed from your subscriptions`,
				timeoutSeconds: 15,
			});
		}
	};

	return (
		<div>
			<AlertBlock alertType="warning" title="Pre-release Feature">
				Notification subscriptions is in the final stages of development and is not yet fully released. Having subscriptions set on this
				page may not result in actually receiving notifications yet. However, subscriptions made now will automatically begin working as
				site features are insturmented to emit these events.
			</AlertBlock>
			<p>
				Notification subscriptions allow you to be notified when things happen. Each topic has subscription options to give you control over
				how you receive the notification. All subscription types implicitly also produce an app notification so you'll see the event when
				you check your notifications on the site.
			</p>
			<ul>
				<li>
					<strong>Immediate</strong> - notifies you via email within a couple minutes of the event
				</li>
				<li>
					<strong>Daily</strong> - delays the notification to be batched in a daily roundup email
				</li>
				<li>
					<strong>Weekly</strong> - delays the notification to be batched in a Weekly roundup email
				</li>
				<li>
					<strong>Blast</strong> - same as immediate, but includes newsletter-style content in the email
				</li>
				<li>
					<strong>App Only</strong> - only see the notification in the notifications list in the app; no email
				</li>
			</ul>
			<h3>{siteName}</h3>
			{/* <SubscriptionGroup
				title="Tags"
				description="Get notified when a page is published for a tag"
				onSelect={setMode}
				onRemove={removeTopic}
				topicKey={TopicKey.YetiCmsPublishTag}
				subscriptions={subscriptions[TopicKey.YetiCmsPublishTag]?.filter((sub) => sub.params['property'] === property) || []}
			/> */}
			<SubscriptionGroup
				title="Pages"
				description={
					<React.Fragment>
						Get notified when a page is published. Subscribe to pages by clicking on the bell to the left of the page's title. If the bell
						is yellow, you're already subscribed and the page will appear in this list.
					</React.Fragment>
				}
				onSelect={setMode}
				onRemove={removeTopic}
				topicKey={TopicKey.YetiCmsPublishPath}
				subscriptions={subscriptions[TopicKey.YetiCmsPublishPath]?.filter((sub) => sub.params['property'] === property) || []}
			/>
			<SubscriptionGroup
				title="Announcements"
				description={`Get notified when an announcement is posted to ${siteName}. Choose Blast to receive the full announcement.`}
				onSelect={setMode}
				onRemove={removeTopic}
				topicKey={TopicKey.YetiCmsAnnouncement}
				subscriptions={subscriptions[TopicKey.YetiCmsAnnouncement]?.filter((sub) => sub.params['property'] === property) || []}
			/>
			{/* <SubscriptionGroup
				title="Newsletter"
				description="Receive the Genesys Cloud Developer Newsletter in your inbox"
				onSelect={setMode}
				onRemove={removeTopic}
				topicKey={TopicKey.YetiCmsDevNewsletterPublish}
				subscriptions={subscriptions[TopicKey.YetiCmsDevNewsletterPublish] || []}
			/> */}
		</div>
	);
}

function modeDisplay(mode: NotificationMode): string {
	switch (mode) {
		case NotificationMode.App: {
			return 'in-app notifications';
		}
		case NotificationMode.Blast: {
			return 'email blast subscriptions';
		}
		case NotificationMode.DigestDaily: {
			return 'daily digest subscriptions';
		}
		case NotificationMode.DigestWeekly: {
			return 'weekly digest subscriptions';
		}
		default: {
			return mode;
		}
	}
}
