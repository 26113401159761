import React, { useEffect, useState } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import Tooltip from '../tooltip/Tooltip';
import { toggleSubscription, useSubscriptions } from '../../helpers/atoms/SubscriptionsAtom';
import { NotificationMode, TopicKey } from '../../helpers/CmsApiTypes';
import { loginsEnabled } from '../../helpers/settings/SettingsManager';

import './SubscribeIcon.scss';

interface IProps {
	pagePath: string;
	className?: string;
}

export default function SubscribeIcon(props: IProps) {
	const subscriptions = useSubscriptions();
	const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
	const [isTooltipActive, setIsTooltipActive] = useState<boolean>(false);

	useEffect(() => {
		setIsSubscribed(
			subscriptions[TopicKey.YetiCmsPublishPath]?.some((s) => s.params['keyPath'] === normalizePath(props.pagePath)) || false
		);
		setIsTooltipActive(false);
	}, [subscriptions, props.pagePath]);

	// Remove component if logins are not enabled
	if (!loginsEnabled) {
		return <></>;
	}

	const onClick = () => {
		toggleSubscription(
			`yeti-cms.${process.env['REACT_APP_SITE_PROPERTY']}.publish.path.${normalizePath(props.pagePath)}`,
			NotificationMode.DigestDaily
		);

		// Update state
		setIsSubscribed(!isSubscribed);

		// Tooltip control
		setIsTooltipActive(true);
		setTimeout(() => setIsTooltipActive(false), 1000);
	};

	return (
		<Tooltip
			text={isSubscribed ? 'Subscribed' : 'Unsubscribed'}
			className={`subscribe-icon-container ${props.className || ''}`}
			isShowing={isTooltipActive}
			position="right"
		>
			<GenesysDevIcon
				className={`subscribe-icon${isSubscribed ? ' subscribe-icon-active' : ''}`}
				onClick={onClick}
				icon={isSubscribed ? GenesysDevIcons.AppNotificationsSolid : GenesysDevIcons.AppNotifications}
			/>
		</Tooltip>
	);
}

function normalizePath(p: string) {
	// Web links do not include "index", but the keypaths used in notifications do
	if (p.endsWith('/')) p += 'index';
	return p;
}
