import React, { useState, useEffect } from 'react';
import { DxToggle } from 'genesys-react-components';

import { NormalizedProperty, UpdateSource, OpenAPIDefinition } from '../../../../../helpers/openapi/OpenAPITypes';
import MapContainer from './MapContainer';
import ArrayContainer from './ArrayContainer';

import './ModelProperty.scss';
import './PrimitiveObjectContainer.scss';

interface IProps {
	initialValue?: any;
	inputId: string;
	descriptionId: string;
	schemaType: string;
	p: NormalizedProperty;
	onFocus: React.Dispatch<React.SetStateAction<boolean>>;
	onStringUpdate: PrimitiveCallback;
	onBooleanUpdate: BooleanCallback;
	onNumberUpdate: PrimitiveCallback;
	onObjectUpdate: PrimitiveCallback;
	onArrayUpdate: PrimitiveCallback;
	oauthHeader?: boolean;
	updateSource?: UpdateSource;
	swagger: OpenAPIDefinition;
	isStringToObjectMap?: boolean;
}

type PrimitiveCallback = (propertyName: string, value?: PrimitiveType | PrimitiveType[]) => void;
type BooleanCallback = (propertyName: string, value?: boolean | undefined) => void;

type PrimitiveType = string | number | boolean | object | undefined;

enum InputType {
	STRING = 'string',
	INTEGER = 'integer',
	FLOAT = 'float',
	BOOLEAN = 'boolean',
	OBJECT = 'object',
	ARRAY = 'array',
}

function PrimitiveObjectContainer(props: IProps) {
	const [value, setValue] = useState<PrimitiveType | PrimitiveType[]>(props.initialValue);
	const [inputType, setInputType] = useState<InputType>();

	useEffect(() => {
		if (props.initialValue !== value) {
			setValue(props.initialValue);
		}
	}, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

	// erase the current value(s) before changing primitive type to avoid invalid type translations
	const handleInputTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(undefined);
		switch (inputType) {
			case InputType.STRING:
				props.onStringUpdate(props.p.propertyName, undefined);
				break;
			case InputType.INTEGER:
			case InputType.FLOAT:
				props.onNumberUpdate(props.p.propertyName, undefined);
				break;
			case InputType.BOOLEAN:
				props.onBooleanUpdate(props.p.propertyName, undefined);
				break;
			case InputType.OBJECT:
				props.onObjectUpdate(props.p.propertyName, undefined);
				break;
			case InputType.ARRAY:
				props.onArrayUpdate(props.p.propertyName, undefined);
				break;
			default:
				break;
		}
		setInputType(Object.values(InputType).find((val: string) => val === e.target.value));
	};

	const stringValueUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		props.onStringUpdate(props.p.propertyName, e.target.value);
	};

	const integerValueUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		props.onNumberUpdate(props.p.propertyName, parseInt(e.target.value));
	};

	const floatValueUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		props.onNumberUpdate(props.p.propertyName, parseFloat(e.target.value));
	};

	const booleanValueUpdated = (value?: boolean) => {
		setValue(value);
		props.onBooleanUpdate(props.p.propertyName, value);
	};

	const objectValueUpdated = (propertyName: string, val?: PrimitiveType) => {
		setValue(val);
		props.onObjectUpdate(propertyName, val);
	};

	const arrayValueUpdated = (propertyName: string, val: PrimitiveType) => {
		setValue(val);
		props.onArrayUpdate(propertyName, val);
	};

	// render the model property based on the primitive type selection
	const renderPropertyEditor = () => {
		switch (inputType) {
			case InputType.STRING:
				return (
					<input
						id={props.inputId}
						aria-describedby={props.descriptionId}
						type={props.oauthHeader ? 'password' : 'text'}
						className="value"
						placeholder={`${props.p.propertyName} (${props.p.typeDisplay})`}
						autoComplete="off"
						onFocus={() => props.onFocus(true)}
						onBlur={() => props.onFocus(false)}
						onChange={stringValueUpdated}
						value={(value as string) || ''}
					/>
				);
			case InputType.INTEGER:
				return (
					<input
						id={props.inputId}
						aria-describedby={props.descriptionId}
						type={props.schemaType as any}
						className="value"
						placeholder={`${props.p.propertyName} (${props.p.typeDisplay})`}
						autoComplete="off"
						onFocus={() => props.onFocus(true)}
						onBlur={() => props.onFocus(false)}
						onChange={integerValueUpdated}
						value={value !== undefined ? (value as number) : ''}
					/>
				);
			case InputType.FLOAT:
				return (
					<input
						id={props.inputId}
						aria-describedby={props.descriptionId}
						type={props.schemaType as any}
						className="value"
						placeholder={`${props.p.propertyName} (${props.p.typeDisplay})`}
						autoComplete="off"
						onFocus={() => props.onFocus(true)}
						onBlur={() => props.onFocus(false)}
						onChange={floatValueUpdated}
						value={value !== undefined ? (value as number) : ''}
					/>
				);
			case InputType.BOOLEAN:
				return props.updateSource === UpdateSource.JSON ? (
					<DxToggle isTriState={true} value={value as boolean | undefined} onChange={booleanValueUpdated} />
				) : (
					<DxToggle isTriState={true} initialValue={value as boolean | undefined} onChange={booleanValueUpdated} />
				);
			case InputType.OBJECT:
				return (
					<MapContainer
						property={props.p}
						onValueUpdated={objectValueUpdated}
						initialValue={props.initialValue}
						swagger={props.swagger}
						updateSource={props.updateSource}
						isPrimitiveObject={true}
					/>
				);
			case InputType.ARRAY:
				return (
					<ArrayContainer
						property={props.p}
						onValueUpdated={arrayValueUpdated}
						initialValue={props.initialValue}
						swagger={props.swagger}
						updateSource={props.updateSource}
						isPrimitiveArray={true}
					/>
				);
			default:
				return;
		}
	};

	if (!inputType) return <></>;

	return (
		<div className="primitive-object-container model-property-container">
			<input
				id={props.inputId}
				aria-describedby={props.descriptionId}
				className="primitive-type-selector"
				type="select"
				onFocus={() => props.onFocus(true)}
				onBlur={() => props.onFocus(false)}
				value={inputType || ''}
				onChange={handleInputTypeChange}
			>
				<option></option>
				<option key="string">string</option>
				<option key="integer">integer</option>
				<option key="float">float</option>
				<option key="boolean">boolean</option>
				<option key="object">object</option>
				<option key="array">array</option>
			</input>
			{renderPropertyEditor()}
		</div>
	);
}

export default PrimitiveObjectContainer;
