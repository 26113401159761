import { LoadingPlaceholder } from 'genesys-react-components';
import { useState, useEffect, useRef } from 'react';

import CmsApi from '../../../helpers/CmsApi';
import { AssetContentString } from '../../../helpers/CmsApiTypes';
import AssetLoader from '../../../helpers/AssetLoader';

import './PdfViewer.scss';

interface IProps {
	url: string;
}

export default function PdfViewer(props: IProps) {
	const assetUrl = useRef<string>('');
	const [content, setContent] = useState<AssetContentString>();

	useEffect(() => {
		// Debounce duplicate updates
		if (assetUrl.current === props.url) return;
		assetUrl.current = props.url;

		if (CmsApi.externalSite) {
			setContent({ content: props.url.startsWith('http') ? props.url : AssetLoader.baseURL + props.url, contentType: '' });
			return;
		} else {
			(async () => {
				// Load content from API
				const res = await CmsApi.getAssetContentBlob(props.url);
				if (!res) return;

				// Prep content for PDF viewer
				const newContent = { content: URL.createObjectURL(res.content as Blob), contentType: '' };

				setContent(newContent);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.url]);

	if (!content) return <LoadingPlaceholder />;

	return <embed className="pdf-viewer" src={content.content} type="application/pdf" />;
}
