import axios, { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import CmsApi from './CmsApi';

const property = process.env.REACT_APP_SITE_PROPERTY || 'none';
const externalSite = process.env.REACT_APP_EXTERNAL_SITE === 'true';

class AssetLoader {
	baseURL: string = '';
	client: AxiosInstance = axios.create();

	constructor() {
		// Determine base URL for assets
		let baseURL = `${CmsApi.baseURL || ''}/api/${encodeURIComponent(property)}/${externalSite ? 'publicassets' : 'assets'}`;
		if (CmsApi.cdnHost) {
			baseURL = `${CmsApi.cdnHost}/assets/${property}`;
		}
		this.setBaseURL(baseURL);
	}

	generateCancelToken() {
		return axios.CancelToken.source();
	}

	isCancel(err: any) {
		return axios.isCancel(err);
	}

	get(pathname: string, returnRawBody?: boolean, cancelToken?: CancelToken) {
		return this.getImpl(pathname, returnRawBody, undefined, cancelToken);
	}

	getBlob(pathname: string, cancelToken?: CancelToken) {
		return this.getImpl(pathname, true, true, cancelToken);
	}

	private getImpl(pathname: string, returnRawBody?: boolean, returnAsBlob?: boolean, cancelToken?: CancelToken) {
		const cfg = {
			method: 'get',
			url: pathname,
			cancelToken,
			params: {},
			headers: {},
		} as AxiosRequestConfig;
		if (!CmsApi.useCDN) {
			cfg.params['content'] = true;
			cfg.headers!['Authorization'] = CmsApi.jwt;
		}
		if (returnAsBlob) {
			cfg.responseType = 'blob';
		}

		// Make request
		return this.client
			.request(cfg)
			.then((response) => {
				if (returnRawBody) return response.data;

				// Stringify objects for content
				let data = typeof response.data === 'object' ? `<pre>${JSON.stringify(response.data, null, 2)}</pre>` : response.data;
				return data;
				// return this.addContentHost(data, pathname);
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				if (err.response && err.response.status === 401 && process.env.REACT_APP_OAUTH_REDIRECT_URI) {
					const state = encodeURIComponent(
						window.location.pathname +
							(window.location.search && window.location.search.length > 1 ? window.location.search : '') +
							(window.location.hash && window.location.hash.length > 1 ? window.location.hash : '')
					);
					window.location.href = process.env.REACT_APP_OAUTH_REDIRECT_URI + '&state=' + state;
				} else {
					throw err;
				}
			});
	}

	put(pathname: string, requestBody?: any, requestContentType?: string, cancelToken?: CancelToken) {
		return this.client
			.request({
				method: 'put',
				url: pathname,
				headers: {
					'content-type': requestContentType,
					Authorization: CmsApi.jwt,
				},
				data: requestBody,
				cancelToken,
			})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				throw err;
			});
	}

	post(pathname: string, requestBody?: any, requestContentType?: string, cancelToken?: CancelToken, timeoutMs?: number, headers?: any) {
		const config: AxiosRequestConfig = {
			method: 'post',
			url: pathname,
			headers: {
				'content-type': requestContentType,
			},
			data: requestBody,
			cancelToken,
		};

		if (headers) {
			const updatedHeaders = Object.assign({}, config.headers);
			for (const [k, v] of Object.entries(headers)) {
				updatedHeaders[k] = v;
			}
			config.headers = updatedHeaders;
		}

		if (timeoutMs && timeoutMs > 0) {
			config.timeout = timeoutMs;
		}

		return axios
			.request(config)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				throw err;
			});
	}

	setBaseURL(baseURL: string) {
		this.baseURL = baseURL;
		this.client = axios.create({
			baseURL: baseURL,
		});
	}

	makeAssetURL(keyPath: string) {
		let assetURL = `${keyPath}`;

		// Don't touch full URLs
		if (assetURL.startsWith('http')) {
			return assetURL;
		}

		// Normalize to start with slash
		if (!assetURL.startsWith('/')) {
			assetURL = '/' + assetURL;
		}

		// Add base URL (CMS API host + path)
		assetURL = this.baseURL + assetURL;

		// Add content param
		if (!CmsApi.useCDN) assetURL += '?content=true';

		return assetURL;
	}
}

export default new AssetLoader();

const SearchLoader = new AssetLoader();
if (process.env.REACT_APP_SEARCH_HOST) {
	// can be set to use search when running locally
	SearchLoader.setBaseURL(process.env.REACT_APP_SEARCH_HOST);
} else {
	// search needs empty content host to bypass other CloudFront path patters
	SearchLoader.setBaseURL('');
}
export { SearchLoader };
