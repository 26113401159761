import { ReactNode } from 'react';

import { StructuredSubscription, TopicKey, NotificationMode } from './CmsApiTypes';
import Tag from '../components/cards/Tag';
import DxLink from '../components/dxlink/DxLink';

// Describes the subscription in a fancier way
export function GetTopicDisplayFancy(sub: StructuredSubscription, topicKey?: TopicKey): ReactNode {
	const displayString = GetTopicDisplayString(sub, topicKey);
	switch (topicKey) {
		case TopicKey.YetiCmsPublishTag: {
			return sub.params['tag'] ? <Tag>{displayString}</Tag> : sub.topic;
		}
		case TopicKey.YetiCmsPublishPath: {
			if (!sub.params['keyPath']) return sub.topic;
			return <DxLink href={decodeURIComponent(sub.params['keyPath'])}>{decodeURIComponent(sub.params['keyPath'])}</DxLink>;
		}
		default: {
			return displayString;
		}
	}
}

// Describes the subscription without mentioning the topic key; for use when that context is already present
export function GetTopicDisplayString(sub: StructuredSubscription, topicKey?: TopicKey): string {
	switch (topicKey) {
		case TopicKey.YetiCmsPublishTag: {
			return sub.params['tag'] ? sub.params['tag'] : sub.topic;
		}
		case TopicKey.YetiCmsPublishPath: {
			return sub.params['keyPath'] ? decodeURIComponent(sub.params['keyPath']) : sub.topic;
		}
		case TopicKey.YetiCmsActivity: {
			return 'Your activity';
		}
		case TopicKey.YetiCmsDevNewsletterPublish: {
			return 'Developer Center Newsletter';
		}
		case TopicKey.YetiCmsAnnouncement: {
			switch (sub.params['type'] || sub.topic) {
				case 'change': {
					return 'Platform Changes';
				}
				case 'newfeature': {
					return 'New Features';
				}
				case 'releasenote': {
					return 'Developer Release Notes';
				}
				case 'info': {
					return 'General Information';
				}
				default: {
					return sub.params['type'] || sub.topic;
				}
			}
		}
		case TopicKey.OssConfigError: {
			return 'Errors relating to your configuration';
		}
		case TopicKey.OssActivity: {
			return 'Your activity';
		}
		default: {
			return sub.topic;
		}
	}
}

// Describes the subscription with context of its topic for standalone use
export function GetTopicStandaloneDisplayString(sub: StructuredSubscription, topicKey: TopicKey): string {
	switch (topicKey) {
		case TopicKey.YetiCmsPublishTag: {
			return sub.params['tag'] ? `Content updates tagged with "${sub.params['tag']}"` : sub.topic;
		}
		case TopicKey.YetiCmsPublishPath: {
			return sub.params['keyPath'] ? `Content updates for ${sub.params['keyPath']}` : sub.topic;
		}
		case TopicKey.YetiCmsActivity: {
			return 'Your Yeti CMS activity';
		}
		case TopicKey.YetiCmsDevNewsletterPublish: {
			return 'Developer Center Newsletter';
		}
		case TopicKey.YetiCmsAnnouncement: {
			switch (sub.params['type'] || sub.topic) {
				case 'change': {
					return 'Platform Change Announcements';
				}
				case 'newfeature': {
					return 'New Feature Announcements';
				}
				case 'releasenote': {
					return 'Developer Release Notes';
				}
				case 'info': {
					return 'General Information Announcements';
				}
				default: {
					return sub.params['type'] || sub.topic;
				}
			}
		}
		case TopicKey.OssConfigError: {
			return 'Errors relating to your open source configuration';
		}
		case TopicKey.OssActivity: {
			return 'Your open source activity';
		}
		default: {
			return sub.topic;
		}
	}
}

export function IsNotificationModeAllowed(topicKey: TopicKey, mode: NotificationMode): boolean {
	switch (topicKey) {
		case TopicKey.YetiCmsPublishTag: {
			return [NotificationMode.App, NotificationMode.DigestDaily, NotificationMode.DigestWeekly].includes(mode);
		}
		case TopicKey.YetiCmsPublishPath: {
			return [NotificationMode.App, NotificationMode.DigestDaily, NotificationMode.DigestWeekly].includes(mode);
		}
		case TopicKey.YetiCmsActivity: {
			return [NotificationMode.App, NotificationMode.Email, NotificationMode.DigestDaily, NotificationMode.DigestWeekly].includes(mode);
		}
		case TopicKey.YetiCmsDevNewsletterPublish: {
			return [NotificationMode.App, NotificationMode.Blast].includes(mode);
		}
		case TopicKey.YetiCmsAnnouncement: {
			return [NotificationMode.App, NotificationMode.DigestDaily, NotificationMode.DigestWeekly, NotificationMode.Blast].includes(mode);
		}
		case TopicKey.OssConfigError: {
			return [NotificationMode.App, NotificationMode.Email, NotificationMode.DigestDaily, NotificationMode.DigestWeekly].includes(mode);
		}
		case TopicKey.OssActivity: {
			return [NotificationMode.App, NotificationMode.Email, NotificationMode.DigestDaily, NotificationMode.DigestWeekly].includes(mode);
		}
		default: {
			return false;
		}
	}
}
