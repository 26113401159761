import { useEffect, useState } from 'react';
import { DxCheckbox, DxLabel } from 'genesys-react-components';
import moment from 'moment';
import { Calendar, momentLocalizer, Event } from 'react-big-calendar';

import CmsApi from '../../../helpers/CmsApi';
import DxLink from '../../dxlink/DxLink';
import { Announcement, AnnouncementType, displayAnnouncementType } from '../../../types';
import { loginsEnabled } from '../../../helpers/settings/SettingsManager';

import './AnnouncementCalendarView.scss';

type AnnouncementTypes = {
	[type in AnnouncementType]: boolean;
};

const defaultAnnouncementTypeFilter = () => {
	return {
		change: false,
		newfeature: false,
		releasenote: false,
		info: false,
	};
};

const getDefaultRange = (range: string): number => {
	// A physical calendar can show up to 6 days before/after the start/end of the month
	if (range === 'startDate') return moment().startOf('month').subtract(1, 'week').valueOf();
	if (range === 'endDate') return moment().endOf('month').add(1, 'week').valueOf();
	return 0;
};

export default function AnnouncementCalendarView() {
	const [announcementTypeFilter, setAnnouncementTypeFilter] = useState<AnnouncementTypes>(defaultAnnouncementTypeFilter());
	const [announcements, setAnnouncements] = useState<Announcement[]>([]);
	const [announcementEvents, setAnnouncementEvents] = useState<Event[]>([]);
	const [startDate, setStartDate] = useState<number>(getDefaultRange('startDate'));
	const [endDate, setEndDate] = useState<number>(getDefaultRange('endDate'));
	const localizer = momentLocalizer(moment);

	useEffect(() => {
		refreshAnnouncements();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	useEffect(() => {
		filterAnnouncement();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [announcementTypeFilter, announcements]);

	const filterAnnouncement = () => {
		const filteredTypes = Object.entries(announcementTypeFilter)
			.filter(([, val]) => val === true)
			.map(([type]) => type);

		const events: Event[] = announcements
			.filter((a) => {
				if (filteredTypes.length === 0) return true;
				return filteredTypes.includes(a.type);
			})
			.map((announcement) => {
				return {
					title: (
						<DxLink
							className={`announcement-card color-code ${announcement.type}-border`}
							href={`/announcements/${announcement.announcementId}`}
							title={announcement.title}
						>
							<span className={`color-code ${announcement.type}-bg`}></span>
							<span className="title"> {announcement.title}</span>
						</DxLink>
					),
					start: moment(announcement.effectiveDate).toDate(),
					end: moment(announcement.effectiveDate).toDate(),
					allDay: true,
				};
			});
		setAnnouncementEvents(events);
	};

	const handleCheckboxInputType = (value: boolean, t: AnnouncementType) => {
		let temp = { ...announcementTypeFilter };
		temp[t] = value;
		setAnnouncementTypeFilter(temp);
	};

	const refreshAnnouncements = async () => {
		let data: Announcement[] | undefined;
		//Show all announcements to logged in user
		if (CmsApi.currentUser) {
			data = await CmsApi.getAnnouncements(startDate, endDate);
		} else {
			data = await CmsApi.getPublicAnnouncements(startDate, endDate);
		}
		if (data) setAnnouncements(data);
	};

	return (
		<div className={`announcement-calendar-view`}>
			<p>Developer Announcements for the Genesys Cloud Platform are organized in the calendar based their effective dates.</p>
			{loginsEnabled && (
				<p>
					You can subscribe to receive notifications for new announcements on your user
					<DxLink href={`/me`}> profile</DxLink>
				</p>
			)}
			<div className="announcement-type-filter-group">
				<DxLabel>
					<span className="label-text">Announcement type filter</span>
				</DxLabel>
				<div className="announcement-type-filter">
					<DxCheckbox
						label={displayAnnouncementType(AnnouncementType.Informational)}
						description="general developer-focused announcements for Genesys Cloud"
						itemValue={AnnouncementType.Informational}
						className={`filter-checkbox ${AnnouncementType.Informational}-border`}
						checked={announcementTypeFilter?.info}
						onCheckChanged={(val) => {
							handleCheckboxInputType(val, AnnouncementType.Informational);
						}}
					/>
					<DxCheckbox
						label={displayAnnouncementType(AnnouncementType.PlatformChange)}
						description="breaking and noteworthy changes to platform features and functionality"
						itemValue={AnnouncementType.PlatformChange}
						className={`filter-checkbox ${AnnouncementType.PlatformChange}-border`}
						checked={announcementTypeFilter?.change}
						onCheckChanged={(val) => {
							handleCheckboxInputType(val, AnnouncementType.PlatformChange);
						}}
					/>
					<DxCheckbox
						label={displayAnnouncementType(AnnouncementType.NewFeature)}
						description="new features for Genesys Cloud developers"
						itemValue={AnnouncementType.NewFeature}
						className={`filter-checkbox ${AnnouncementType.NewFeature}-border`}
						checked={announcementTypeFilter?.newfeature}
						onCheckChanged={(val) => {
							handleCheckboxInputType(val, AnnouncementType.NewFeature);
						}}
					/>
					<DxCheckbox
						label={displayAnnouncementType(AnnouncementType.ReleaseNotes)}
						description="package and library release notes"
						itemValue={AnnouncementType.ReleaseNotes}
						className={`filter-checkbox ${AnnouncementType.ReleaseNotes}-border`}
						checked={announcementTypeFilter?.releasenote}
						onCheckChanged={(val) => {
							handleCheckboxInputType(val, AnnouncementType.ReleaseNotes);
						}}
					/>
				</div>
			</div>

			<div>
				<Calendar
					views={{
						work_week: false,
						day: false,
						agenda: true,
						month: true,
						week: false,
					}}
					defaultView="month"
					onRangeChange={(range) => {
						const r = JSON.parse(JSON.stringify(range));
						//only update announcements for month change
						if (r.start && r.end) {
							setStartDate(moment(r.start).valueOf());
							setEndDate(moment(r.end).valueOf());
						}
					}}
					toolbar={true}
					style={{ height: '800px' }}
					localizer={localizer}
					events={announcementEvents}
				/>
			</div>
		</div>
	);
}
