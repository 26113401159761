import { LoadingPlaceholder } from 'genesys-react-components';
import React, { useEffect, useState } from 'react';
import AssetLoader from '../../../helpers/AssetLoader';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import DxLink from '../../dxlink/DxLink';

import './PostmanFiles.scss';

interface PostmanFileData {
	name: string;
	filename: string;
}

export default function PostmanFiles() {
	const [data, setData] = useState<PostmanFileData[] | undefined>();

	// Constructor
	useEffect(() => {
		AssetLoader.get('/data/postman/postmanfilenames.json', true)
			.then((data) => setData(data as PostmanFileData[]))
			.catch(() =>
				addToast({
					title: 'Data load failure',
					message:
						'Failed to load Postman files; refresh the page to try again. Please post on the Genesys Cloud Developer Forum if this error persists.',
					toastType: ToastType.Critical,
				})
			);
	}, []);

	if (!data) return <LoadingPlaceholder text="Loading Postman files" />;

	return (
		<div>
			<div className="postman-list">
				<h3>API Groups</h3>
				<ul>
					{data.map((file, i) => (
						<li key={i}>
							<DxLink href={`${AssetLoader.baseURL}/data/postman/collections/${file.filename}`}>{file.name}</DxLink>
						</li>
					))}
				</ul>
			</div>
			<h3>Additional Resources</h3>
			<p>
				For the full API in a single swagger collection, use{' '}
				<DxLink href={`${AssetLoader.baseURL}/data/postman/collections/full.json`}>the full collection</DxLink>
			</p>
			<p>
				Download the environment file here:{' '}
				<DxLink href={`${AssetLoader.baseURL}/data/postman/genesyscloud.postman_environment`}>genesyscloud.postman_environment</DxLink>
			</p>
		</div>
	);
}
