import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { DxLabel, LoadingPlaceholder } from 'genesys-react-components';

import { Announcement } from '../../../types';
import CmsApi from '../../../helpers/CmsApi';
import TagCloud from '../../tagcloud/TagCloud';
import MarkdownDisplay from '../../markdown/display/MarkdownDisplay';
import { setPageTitle } from '../../../helpers/atoms/PageTitleAdtom';

import './AnnouncementView.scss';

export default function AnnouncementView() {
	const params = useParams();
	const announcementId = params.announcementId || '';
	const [announcement, setAnnouncement] = useState<Announcement>();
	const [content, setContent] = useState<string>('');

	useEffect(() => {
		refreshAnnouncement();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.announcementId]);

	const refreshAnnouncement = async () => {
		setAnnouncement(undefined);
		setContent('');

		const a = CmsApi.currentUser ? await CmsApi.getAnnouncement(announcementId) : await CmsApi.getPublicAnnouncement(announcementId);
		setAnnouncement(a);
		if (!a) {
			setContent(`:::critical
The announcement was not found. Please check the URL and try again.
:::`);
		} else {
			// Update page title
			setPageTitle(a.title);

			// Build markdown content
			let newContent = `## Summary\n\n${a.summary}\n\n`;
			if (a.effectiveDate) {
				newContent += `## Effective Date\n\n${moment(a.effectiveDate).format('dddd, LL')}\n\n`;
			}
			if (a.additionalResources) {
				newContent += `## Additional Resources\n\n${a.additionalResources}\n\n`;
			}
			if (a.jiraIssues) {
				newContent += `## Issue References\n\n${a.jiraIssues}\n\n`;
			}
			if (a.details) {
				newContent += `## Details\n\n${a.details}\n\n`;
			}
			if (a.impactedResources) {
				newContent += `## Impacted Resources\n\n${a.impactedResources}\n\n`;
			}
			if (a.explanation) {
				newContent += `## Explanation\n\n${a.explanation}\n\n`;
			}
			if (a.customerImpact) {
				newContent += `## Customer Impact\n\n${a.customerImpact}\n\n`;
			}
			if (a.package) {
				newContent += `## Package\n\n${a.package}\n\n`;
			}
			if (a.releaseNotes) {
				newContent += `## Release Notes\n\n${a.releaseNotes}\n\n`;
			}

			setContent(newContent);
		}
	};

	if (!announcement && !content) return <LoadingPlaceholder />;

	return (
		<div className="announcement-view">
			{announcement?.tags && announcement.tags.length > 0 && (
				<DxLabel label="Tags">
					<TagCloud className="announcement-tags" tags={announcement.tags || []} align="left" />
				</DxLabel>
			)}
			{content && <MarkdownDisplay markdown={content} />}
		</div>
	);
}
