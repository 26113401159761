import React, { CSSProperties } from 'react';

import './InlinePlaceholder.scss';

interface IProps {
	text?: string;
	width?: string;
	height?: string;
	className?: string;
}

export default function InlinePlaceholder(props: IProps) {
	const style: CSSProperties = {
		minWidth: props.width,
		minHeight: props.height,
	};
	return (
		<div className={`inline-placeholder-container ${props.className || ''}`} style={style}>
			<div className="inline-placeholder"></div>
			<span className="inline-placeholder-text">{props.text || <>&nbsp;</>}</span>
		</div>
	);
}
