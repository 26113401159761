import React from 'react';
import moment from 'moment';

import { CardClickAction, WrapWithLink } from '../Card';
import Tag from '../Tag';
import MarkdownDisplay from '../../markdown/display/MarkdownDisplay';
import { ContentIndexPage } from '../../../helpers/Sitemap';
import AuthorInfo from '../../authorinfo/AuthorInfo';

import './PlainCard.scss';

interface IProps {
	data: ContentIndexPage;
	onClickAction?: CardClickAction;
	maxTextHeight?: number;
}

export default function PlainCard(props: IProps) {
	let tags;
	if (props.data.tags) {
		tags = (
			<div className="tags">
				{props.data.tags.split(',').map((s, i) => (
					<Tag key={i}>{s.trim()}</Tag>
				))}
			</div>
		);
	}

	const style: React.CSSProperties = {};
	if (props.maxTextHeight) {
		style.height = `${props.maxTextHeight}px`;
	}
	return (
		<div className="dx-card-plain">
			<span className="title">{WrapWithLink(props.data.title || 'CARD TITLE', props.data.link, props.onClickAction)}</span>
			{props.data.subtitle ? <span className="subtitle">{props.data.subtitle}</span> : undefined}
			{props.data.publishTimestamp ? (
				<span className="publish-date">{moment(props.data.publishTimestamp).format('MMMM Do, YYYY')}</span>
			) : (
				''
			)}
			<span className="text" style={style} title={props.data.teaser}>
				{props.data.teaser && <MarkdownDisplay markdown={props.data.teaser} />}
				{!props.data.teaser && <em>This item does not have a description.</em>}
			</span>
			{tags}
			{props.data.attributedAuthors && (
				<div className="card-authors">
					{props.data.attributedAuthors.map((authorId) => (
						<AuthorInfo key={authorId} authorId={authorId} mode="mini" />
					))}
				</div>
			)}
		</div>
	);
}
