import { LoadingPlaceholder } from 'genesys-react-components';
import React, { useEffect, useRef, useState } from 'react';
import { readBlob } from '../../../helpers/CmsApiTypes';
import InlinePlaceholder from '../../newloadingplaceholder/InlinePlaceholder';
import AssetLoader from '../../../helpers/AssetLoader';
import CmsApi from '../../../helpers/CmsApi';

const hostnameRegex = /^https?:\/\//i;

interface IProps {
	src: string;
	alt?: string;
	title?: string;
	useInlinePlaceholder?: boolean;
}

export default function ImageAsset(props: IProps) {
	const [imgSrc, setImgSrc] = useState<string>();
	const [loadingError, setLoadingError] = useState<string>();
	const currentSrc = useRef<string>();

	useEffect(() => {
		if (currentSrc.current === props.src) return;
		currentSrc.current = props.src;

		(async () => {
			try {
				setLoadingError(undefined);
				setImgSrc(undefined);
				const match = hostnameRegex.exec(props.src);
				let src = new URL(props.src, window.location.href);
				const isSameHost = window.location.hostname.toLowerCase() === src.hostname.toLowerCase();
				if (match && !isSameHost) {
					// Set src to URL to let the browser load the image normally
					setImgSrc(props.src);
				} else {
					if (CmsApi.useCDN) {
						// Use the CDN to load the image normally
						setImgSrc(AssetLoader.makeAssetURL(src.pathname));
					} else {
						// Fetch image and set src as encoded image data
						const content = await AssetLoader.getBlob(src.pathname);
						if (!content) {
							setLoadingError('Failed to load image');
						}
						let str = await readBlob(content);
						setImgSrc(str);
					}
				}
			} catch (err) {
				console.error(err);
				setLoadingError('Failed to load image');
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.src]);

	if (loadingError) return <em title={props.src}>{loadingError}</em>;
	if (!imgSrc) {
		if (props.useInlinePlaceholder) return <InlinePlaceholder width="100%" height="100%" />;
		else return <LoadingPlaceholder />;
	} else return <img src={imgSrc} alt={props.alt} title={props.title} />;
}
