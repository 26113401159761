// DataCollectorEvent defines the events raised within the app
export enum DataCollectorEvent {
	Loaded = 'loaded',
	ThemeSwitch = 'theme-switch',
}

// RumEvents maps DataCollectorEvent events to RUM-specific event keys
export const RumEventKeys: { [key in DataCollectorEvent]: string } = {
	[DataCollectorEvent.Loaded]: 'com.genesys.app.loaded',
	[DataCollectorEvent.ThemeSwitch]: 'com.genesys.app.theme_switch',
};

// ContentsquareEventKeys maps DataCollectorEvent events to Contentsquare-specific event keys
// export const ContentsquareEventKeys: { [key in DataCollectorEvent]: string } = {
// 	[DataCollectorEvent.Loaded]: '???',
// 	[DataCollectorEvent.ThemeSwitch]: '???',
// };

export interface ICollectorClass {
	init: VoidFunction;
	event: { (eventType: DataCollectorEvent, eventData?: object): void };
}

/*** START Q-generated CS_CONF types */

export interface ContentsquareConfig {
	isSMB: boolean;
	projectId: number;
	status: number;
	hostnames: string[];
	crossDomainTracking: number;
	crossDomainSingleIframeTracking: number;
	consentRequired: number;
	allowSubdomains: number;
	visitorCookieTimeout: number;
	sampleRate: number;
	replayRecordingRate: number;
	validationRate: number;
	lastTrackingDraw: number;
	trackerDomain: string;
	recordingDomain: string;
	useMalkaPipeline: number;
	ed: string;
	eMerchandisingEnabled: number;
	mouseMoveHeatmapEnabled: number;
	autoInsightsEnabled: number;
	jsErrorsEnabled: number;
	customErrorsEnabled: number;
	jsCustomErrorsEnabled: number;
	apiErrorsEnabled: number;
	customHashIdEnabled: number;
	recordingEncryptionEnabled: number;
	recordingEncryptionPublicKey: null;
	recordingEncryptionPublicKeyId: number;
	secureCookiesEnabled: number;
	triggerSessionReplayEnabled: number;
	triggerSessionReplayRegex: null;
	dynamicIdRegex: null;
	whitelistedAttributes: null;
	replayRecordingUnmaskedUrlRegex: string;
	replayRecordingUnmaskedUrlRegexRules: any[];
	replayRecordingMaskedUrlRegexRules: any[];
	replayRecordingMaskedUrlRegex: string;
	anonymisationMethod: null;
	tagDeploymentMode: string;
	experimental: null;
	iframesTracking: number;
	textVisibilityEnabled: number;
	cookielessTrackingEnabled: number;
	malkaUrlEnabled: number;
	malkaEtrEnabled: number;
	pathComputationRules: Record<string, any>;
	asyncSerializerEnabled: number;
	pendingInactivityTimeout: number;
	accessibilityEnabled: number;
	uxaDomain: string;
	webviewsTrackingEnabled: number;
	useStaticResourceManager: number;
	performanceTimingOptions: PerformanceTimingOptions;
	replayConsentRequiredForSession: number;
	eventsApiEnabled: number;
	clickedElementTextEnabled: number;
	apiErrors: ApiErrors;
	customErrors: CustomErrors;
	displayAdOptions: null;
	taskSchedulerOptions: TaskSchedulerOptions;
	malkaQuotaServiceDomain: string;
	malkaRecordingDomain: string;
	staticResourceManagerDomain: string;
	voc: Voc;
	implementations: Implementation[];
	integrations: string[];
}

interface PerformanceTimingOptions {
	withNavigation: boolean;
	withResource: boolean;
}

interface ApiErrors {
	validCustomHeaders: string[];
	plainCustomHeaders: string[];
	validUrls: string[];
	configurableApiErrorRules: any[];
	collectStandardHeaders: number;
	collectQueryParam: number;
	collectRequestBody: number;
	collectResponseBody: number;
	collectionRules: any[];
}

interface CustomErrors {
	consoleMessageLogLevels: any[];
}

interface TaskSchedulerOptions {
	enabled: number;
}

interface Voc {
	siteId: null;
	enabled: number;
}

interface Template {
	name: string;
	args: {
		cssSelectors?: string[];
		attributes?: any[];
		path?: string;
		lifespan?: string[];
		decodeURI?: string;
	};
}

interface Trigger {
	name: string;
	args: {
		state?: string;
		selector?: string;
		listeners?: string;
		useDebounce?: string;
		window?: number;
	};
	condition?: {
		operator: string;
		args: Array<{
			operator: string;
			args: string[];
		}>;
	};
}

interface Implementation {
	template: Template;
	triggers: Trigger[];
}

/*** END Q-generated CS_CONF types */
