import React, { useEffect, useState } from 'react';
import yaml from 'js-yaml';
import Modal from 'react-modal';

import Card from '../cards/Card';

import AssetLoader from '../../helpers/AssetLoader';

import './VideoCatalog.scss';
import MarkdownDisplay from '../markdown/display/MarkdownDisplay';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxAccordion, DxAccordionGroup } from 'genesys-react-components';

interface VideoCategory {
	category: string;
	description?: string;
	videos: VideoDetails[];
}

interface VideoDetails {
	title: string;
	description: string;
	url: string;
}

Modal.setAppElement('#root');

export default function VideoCatalog() {
	const [videos, setVideos] = useState<VideoCategory[] | undefined>();
	const [selectedVideo, setSelectedVideo] = useState<VideoDetails | undefined>();
	const [error, setError] = useState<string | undefined>();

	// Constructor
	useEffect(() => {
		AssetLoader.get('/data/videos.yml', true, undefined)
			.then((data) => {
				setVideos(yaml.load(data) as VideoCategory[]);
			})
			.catch((err) => {
				console.error(err);
				setError(`Error loading guide data: ${err.message || 'unknown error'}`);
			});
	}, []);

	return (
		<div className="video-catalog-container">
			{error && <div className="alert alert-danger">{error}</div>}
			<p>Welcome to the Genesys Cloud Video page. Expand the categories below to browse our video playlists.</p>
			<DxAccordionGroup>
				{videos &&
					videos.map((category, i) => (
						<DxAccordion key={i} title={category.category}>
							{category.description && <MarkdownDisplay markdown={category.description} />}
							<div className="video-catalog">
								{category.videos.map((video, ii) => (
									<div className="card-container" key={ii}>
										<Card
											cardStyle="image"
											onClickAction={() => setSelectedVideo(video)}
											data={{
												title: video.title,
												teaser: video.description,
												image: getImage(video.url),
												link: '',
											}}
										/>
									</div>
								))}
							</div>
						</DxAccordion>
					))}
			</DxAccordionGroup>
			{selectedVideo && (
				<Modal className="video-modal" isOpen={selectedVideo !== undefined} contentLabel="video stuff">
					<div className="video-container">
						<span className="video-title">{selectedVideo.title}</span>
						{generateEmbedIframe(selectedVideo.url)}
					</div>
					<GenesysDevIcon icon={GenesysDevIcons.AppTimes} className="close-modal" onClick={() => setSelectedVideo(undefined)} />
				</Modal>
			)}
		</div>
	);
}

// Right click on video and copy link. Looks like: https://youtu.be/dBEhmO1AaS0
const YOUTUBE_REGEX = /youtu\.be\/(.+)$/i;

function getImage(url: string) {
	const youtubeMatch = YOUTUBE_REGEX.exec(url);
	if (youtubeMatch) {
		return `https://img.youtube.com/vi/${youtubeMatch[1]}/0.jpg`;
	}
}

function generateEmbedIframe(url: string) {
	// Default to URL that was given, but logic below may rewrite URLs for certain providers
	let embedUrl = url;

	// YouTube
	const youtubeMatch = YOUTUBE_REGEX.exec(url);
	if (youtubeMatch) {
		embedUrl = `https://youtube.com/embed/${youtubeMatch[1]}`;
	}

	return (
		<iframe
			title="embedded youtube video"
			src={embedUrl}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	);
}
