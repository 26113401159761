import React from 'react';
import moment from 'moment';

import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import CodeFence from '../../../codefence/CodeFence';
import { pinEvent } from '../../../../helpers/atoms/ChannelAtoms';
import { TopicEvent } from '../notificationtopics/NotificationDefinitions';

import './EventCardStyle.scss';

interface Props {
	event: TopicEvent;
}

interface CustomEventData {
	label: string;
	data: string;
}

export default function EventCard(props: Props) {
	let customEventList: CustomEventData[] = [];
	const commonTopics = ['presence', 'activity', 'routingStatus'];

	const event = props.event;
	const eventContent = event.eventData;

	const matchTopic = commonTopics.find((t) => eventContent.topicName.includes(t));

	switch (matchTopic) {
		case 'presence':
			customEventList.push({ label: 'Message: ', data: eventContent.eventBody.message || 'No Message' });
			customEventList.push({ label: 'Presence: ', data: eventContent.eventBody.presenceDefinition.systemPresence });
			break;
		case 'activity':
			customEventList.push({ label: 'Out of Office: ', data: eventContent.eventBody.outOfOffice.active.toString().toUpperCase() });
			customEventList.push({ label: 'Routing Status: ', data: eventContent.eventBody.routingStatus.status });
			break;
		case 'routingStatus':
			customEventList.push({ label: 'Status: ', data: eventContent.eventBody.routingStatus.status });
			customEventList.push({ label: 'startTime: ', data: eventContent.eventBody.routingStatus.startTime });
			break;
		default:
			customEventList.push({ label: 'Correlation ID: ', data: eventContent.metadata.CorrelationId });
	}

	return (
		<div className="event-card-container">
			<div className="event-details-container">
				<div className="event-details">
					<span className="event-details-label">Topic :</span>
					<span title={eventContent.topicName}>{eventContent.topicName}</span>

					{customEventList.length > 0 ? (
						<React.Fragment>
							<span className="event-details-label variable-event-details-label">{customEventList[0].label}</span>
							<span title={customEventList[0].data}>{customEventList[0].data}</span>
						</React.Fragment>
					) : (
						<>
							<span></span>
							<span></span>
						</>
					)}

					<span>
						{event.isPinned ? (
							<span title="Unpin Event Card">
								<GenesysDevIcon className="pin" icon={GenesysDevIcons.AppPinSolid} onClick={() => pinEvent(event)} />
							</span>
						) : (
							<span title="Pin Event Card">
								<GenesysDevIcon className="pin" icon={GenesysDevIcons.AppPinStroke} onClick={() => pinEvent(event)} />
							</span>
						)}
					</span>

					<span className="event-details-label">Timestamp: </span>
					<span title={moment(event.time).format('LLLL')}>{moment(event.time).format('LLLL')}</span>

					{customEventList.length > 1 ? (
						<React.Fragment>
							<span className="event-details-label variable-event-details-label">{customEventList[1].label}</span>
							<span>{customEventList[1].data}</span>
						</React.Fragment>
					) : (
						<>
							<span></span>
							<span></span>
						</>
					)}
				</div>
				<CodeFence
					title="Event Details"
					autoCollapse={true}
					className="code-fence"
					value={JSON.stringify(eventContent.eventBody, null, 2)}
					language="json"
				/>
			</div>
		</div>
	);
}
