import React, { useEffect, useRef, useState } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton, LoadingPlaceholder } from 'genesys-react-components';
import moment from 'moment';

import CmsApi from '../../../../../helpers/CmsApi';
import { MarkReadRequest, NotificationContent } from '../../../../../helpers/CmsApiTypes';
import MarkdownDisplay from '../../../../markdown/display/MarkdownDisplay';
import DxLink from '../../../../dxlink/DxLink';
import { loginsEnabled } from '../../../../../helpers/settings/SettingsManager';

import './NotificationsWidget.scss';

export default function NotificationsWidget() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [hasNew, setHasNew] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<NotificationContent[]>();
	const [cursor, setCursor] = useState<string>();

	const wrapperRef = useRef(null as any);

	useEffect(() => {
		if (!loginsEnabled) return;

		(async () => {
			const res = await CmsApi.getNotifications();
			setNotifications(res?.data || []);
			setCursor(res?.cursor);
		})();

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setHasNew(notifications?.some((nc) => nc.read !== true) || false);
	}, [notifications]);

	// Remove component if logins are not enabled
	if (!loginsEnabled) return <></>;

	function handleClickOutside(event: any) {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	}

	return (
		<div className="notifications-widget">
			<DxButton className={`notifications-bell${isOpen ? ' open' : ''}`} type="link" onClick={() => setIsOpen(!isOpen)}>
				<GenesysDevIcon
					className={`${hasNew ? ' active-alerts' : ''}`}
					icon={hasNew ? GenesysDevIcons.AppNotificationsSolid : GenesysDevIcons.AppNotifications}
				/>
			</DxButton>
			<div className={`notifications-container${isOpen ? ' open' : ''}`} ref={wrapperRef}>
				<div className="notifications-header">
					<span className="h7">Notifications</span>
					{notifications && hasNew && (
						<DxButton
							type="link"
							className="dismiss-link"
							onClick={async () => {
								const requests: MarkReadRequest[] = [];
								const n = [...notifications];
								notifications.forEach((nc) => {
									if (!nc.read) {
										nc.read = true;
										requests.push({ messageId: nc.messageId, timestamp: nc.timestamp, pinnedUntil: nc.pinnedUntil });
									}
								});
								await CmsApi.markNotificationsRead(requests);
								setNotifications(n);
							}}
						>
							Dismiss All
						</DxButton>
					)}
				</div>
				{notifications === undefined && <LoadingPlaceholder />}
				{notifications && notifications.length === 0 && <p className="no-notifications">You have no notifications</p>}
				{notifications && notifications.length > 0 && (
					<div className="notifications-list">
						{notifications.map((notification) => (
							<div
								key={`${notification.messageId}-${notification.timestamp}`}
								className="notification"
								onMouseUp={async (e) => {
									if (notification.read === true) return;
									await CmsApi.markNotificationRead(notification.messageId, notification.timestamp, notification.pinnedUntil);
									const n = [...notifications];
									const i = n.findIndex(
										(nc) =>
											nc.messageId === notification.messageId &&
											nc.timestamp === notification.timestamp &&
											nc.pinnedUntil === notification.pinnedUntil
									);
									if (i >= 0) {
										n[i] = { ...n[i] };
										n[i].read = true;
										setNotifications(n);
									}
								}}
							>
								<p className="subject">
									{notification.pinnedUntil && (
										<span className="important-pin" title={`Pinned until ${moment(notification.pinnedUntil).format('MMMM Do YYYY')}`}>
											<GenesysDevIcon
												icon={notification.pinnedUntil > Date.now() ? GenesysDevIcons.AppPinSolid : GenesysDevIcons.AppPinStroke}
											/>
										</span>
									)}
									{notification.link && <DxLink href={notification.link}>{notification.subject}</DxLink>}
									{!notification.link && (notification.subject || 'Notification')}
									{!notification.read && <span className="newtag">NEW</span>}
								</p>
								<div className="content">
									<MarkdownDisplay markdown={unescapeHtmlChars(notification.content)} />
								</div>
								<p className={`footer${notification.read !== true ? ' new' : ''}`}>
									<span title={moment(notification.timestamp).format('MMMM Do YYYY, h:mm:ss a')}>
										{moment.duration(moment(notification.timestamp).diff(moment())).humanize(true)}
									</span>
								</p>
							</div>
						))}
						{cursor && (
							<DxButton
								type="secondary"
								className="load-more-footer"
								onClick={async () => {
									const res = await CmsApi.getNotifications(cursor);
									if (!res?.data) return;
									setNotifications([...(notifications || []), ...res.data]);
									setCursor(res.cursor);
								}}
							>
								Load more...
							</DxButton>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

// unescapeHtmlChars unescapes HTML escape characters. This can make the content unsafe. Do not use its output in an unsafe way!
function unescapeHtmlChars(input: string) {
	var doc = new DOMParser().parseFromString(input, 'text/html');
	return doc.documentElement.textContent || '';
}
