import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton, DxItemGroupItem, DxItemGroupItemValue, LoadingPlaceholder } from 'genesys-react-components';

import Card, { CardStyles } from './Card';
import Paginator, { Pagination } from '../paginator/Paginator';
import { FilterGroup, setFilters } from '../../helpers/atoms/InPageFilters';
import { ContentIndexType } from '../../helpers/CmsApiTypes';
import CmsApi from '../../helpers/CmsApi';
import { ContentIndexPage } from '../../helpers/Sitemap';

import './CardCatalog.scss';

interface IProps {
	indexType?: ContentIndexType;
	cardStyle?: CardStyles;
	subtypeFilterLabel?: string;
}

interface SubtypeMap {
	[subtype: string]: string;
}

const MIN_PAGE_SIZE = 6;
const normalSort = (a: String, b: String) => (a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0);
const camelizeRegex = /(^|\s)(.)/gm;

export default function CardCatalog(props: IProps) {
	const [cardData, setCardData] = useState<ContentIndexPage[] | undefined>();
	const [filteredCards, setFilteredCards] = useState<ContentIndexPage[] | undefined>();
	const [pagination, setPagination] = useState<Pagination>({ startItem: 1, endItem: MIN_PAGE_SIZE, page: 1, pageSize: MIN_PAGE_SIZE });
	const [tagFilters, setTagFilters] = useState<DxItemGroupItemValue[]>([]);
	const [subtypes, setSubtypes] = useState<SubtypeMap>();
	const [selectedSubtype, setSelectedSubtype] = useState<string>();
	const location = useLocation();
	const navigate = useNavigate();

	// Constructor
	useEffect(() => {
		(async () => {
			if (!props.indexType) return;
			const contentIndex = await CmsApi.getIndex(props.indexType);
			if (!contentIndex) return;
			let indexData: ContentIndexPage[] = Object.values(contentIndex);
			indexData = indexData.sort((a, b) => {
				return !a.publishTimestamp && !b.publishTimestamp
					? 0
					: !a.publishTimestamp
					? -1
					: !b.publishTimestamp
					? 1
					: a.publishTimestamp > b.publishTimestamp
					? -1
					: a.publishTimestamp < b.publishTimestamp
					? 1
					: 0;
			});
			const subtypeMap: SubtypeMap = {};

			indexData.forEach((val) => {
				// Collect subtype
				if (val.subtype) {
					let subtypeName = val.subtype;
					subtypeName = subtypeName.replaceAll(
						camelizeRegex,
						(substring: string, m1: string, m2: string) => `${m1}${(m2 || '').toUpperCase()}`
					);
					subtypeMap[subtypeName] = val.subtype;
				}
			});

			// Set subtypes
			if (Object.keys(subtypeMap).length > 0) {
				subtypeMap['All'] = '';
				setSubtypes(subtypeMap);
			}
			// Set selected subtype
			const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
			if (queryParams['subtype']) setSelectedSubtype(`${queryParams['subtype'] || ''}`);

			setCardData(indexData);
			setFilteredCards(indexData);

			// Scrape data for filters
			const filterData = [] as FilterGroup[];
			filterData.push({
				label: 'Tags',
				options:
					// Split tag data by commas, flatten, and remove duplicates
					Array.from(new Set(indexData.map((val) => (val.tags || '').split(',').map((val) => val.trim())).flat()).values())
						// Remove empty entries
						.filter((val) => val !== '' && val !== undefined)
						// Sort alphabetically
						.sort(normalSort)
						// Translate to filter items
						.map((val) => {
							return { label: val, value: val } as DxItemGroupItem;
						}),
				onItemsChanged: (items: DxItemGroupItemValue[]) => setTagFilters(items),
			});
			setFilters(filterData);
		})();

		return () => {
			setFilters(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!cardData) return;

		// Filter tags
		let activeFilters = tagFilters.filter((f) => f.isSelected).map((f) => f.item.value);
		let cards = [...cardData];
		if (activeFilters.length > 0) {
			cards = cards.filter((card) => activeFilters.some((value) => card.tags?.includes(value)));
		}

		// Filter subtype
		if (selectedSubtype) {
			cards = cards.filter((card) => card.subtype === selectedSubtype);
		}
		// Update subtype query param
		const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
		if (selectedSubtype !== undefined && selectedSubtype !== (queryParams['subtype'] || '')) {
			if (selectedSubtype) queryParams['subtype'] = selectedSubtype;
			else delete queryParams['subtype'];
			let queryString = Object.keys(queryParams)
				.map((p) => `${p}=${encodeURIComponent(`${queryParams[p] || ''}`)}`)
				.join('&');
			if (queryString) queryString = '?' + queryString;
			const l = window.location;
			navigate(`${l.pathname}${queryString}${l.hash}`);
		}

		setFilteredCards(cards);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardData, tagFilters, selectedSubtype, location]);

	return filteredCards ? (
		<div className="card-catalog">
			{subtypes && (
				<div className="subtype-container">
					{props.subtypeFilterLabel && <span className="subtype-filter-label">{props.subtypeFilterLabel}</span>}
					<div className="subtype-filter">
						{Object.entries(subtypes).map(([display, value]) => (
							<DxButton
								key={display}
								type={(selectedSubtype || '') === value ? 'primary' : 'secondary'}
								onClick={() => setSelectedSubtype(value)}
							>
								{display}
							</DxButton>
						))}
					</div>
				</div>
			)}
			<div className="card-list">
				{filteredCards.slice(pagination.startItem - 1, pagination.endItem).map((d, i) => (
					<div className="card-container" key={i}>
						<Card data={d} icon={d.icon as GenesysDevIcons | undefined} cardStyle={props.cardStyle} maxTextHeight={200} />
					</div>
				))}
			</div>
			<Paginator itemCount={filteredCards.length} minPageSize={MIN_PAGE_SIZE} onPaginationChanged={(p) => setPagination(p)} />
		</div>
	) : (
		<LoadingPlaceholder />
	);
}
