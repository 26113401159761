import React from 'react';

import { CardClickAction, WrapWithLink } from '../Card';
import PlainCard from './PlainCard';
import { ContentIndexPage } from '../../../helpers/Sitemap';

import './ImageCard.scss';

import defaultHeaderImage from '../noimage.svg';
import defaultHeaderImageYeti from '../defaultheader-yeti.png';
import ImageAsset from '../../markdown/imageasset/ImageAsset';

interface IProps {
	data: ContentIndexPage;
	orientation?: 'tall' | 'wide';
	onClickAction?: CardClickAction;
	defaultImage?: 'yeti' | 'mountains';
	maxTextHeight?: number;
}

export default function ImageCard(props: IProps) {
	const formatImagePath = (card: ContentIndexPage): string => {
		// If it has a path, don't touch it
		if (!card.image || card.image.startsWith('/')) return card.image || '';

		// Build full path
		const match = card.link?.match(/(\/.*\/)/i);
		return (match ? match[0] : '/') + card.image;
	};

	let image;
	if (props.data.image) {
		image = props.data.image.toLowerCase().startsWith('http') ? (
			<img src={props.data.image} alt="headline" />
		) : (
			<ImageAsset src={formatImagePath(props.data)} alt="headline" useInlinePlaceholder={true} />
		);
	} else {
		image = (
			<img
				src={props.defaultImage === 'mountains' ? defaultHeaderImage : defaultHeaderImageYeti}
				alt="headline"
				className={`placeholder-image`}
			/>
		);
	}
	image = WrapWithLink(image, props.data.link, props.onClickAction);

	return (
		<div className={`dx-card-image ${props.orientation || 'tall'}`}>
			<div className={`card-image${props.defaultImage === 'mountains' ? ' mountains' : ' yeti'}`}>{image}</div>
			<PlainCard data={props.data} onClickAction={props.onClickAction} maxTextHeight={props.maxTextHeight} />
		</div>
	);
}
